import "./custom.css";
import { useEffect, useRef, useState, useCallback } from "react";
import { bytesToMB } from "../../helpers";

function FileUpload({ set }) {
  const [file, setFile] = useState(null);
  const fileInput = useRef(null);
  const fileArea = useRef(null);

  useEffect(() => set(file), [file]);

  const handleImageChange = (e) => {
    if (e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }
    setFile(e.target.files[0]);
  };

  const handleClick = (e) => {
    fileInput.current.click();
  };
  const onDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setFile(e.dataTransfer.files[0]);
  }, []);
  const onDragOver = (event) => {
    event.preventDefault();
  };
  const onDragLeave = () => {};

  const handleRemove = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFile(null);
    fileInput.current.value = null;
  };

  return (
    <div className="file-upload" style={{ width: "100%" }}>
      <div
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        className={`image-upload-wrap ${!!file ? "filled" : "unfilled"}`}
        ref={fileArea}
        onClick={handleClick}
      >
        <input
          className="file-upload-input"
          ref={fileInput}
          onChange={handleImageChange}
          type="file"
          accept="image/*,application/pdf"
        />
        {!!file ? (
          <div className="flex flex-center" style={{ width: "100%" }}>
            <div
              className="drag-text flex flex-col"
              style={{ textAlign: "left", paddingLeft: 20, flex: 1 }}
            >
              <h3 className="font-semibold">{file.name}</h3>
              <h2>{bytesToMB(file.size)} MB</h2>
            </div>
            <i
              className="fas fa-trash custom-trash-icon"
              onClick={handleRemove}
            />
          </div>
        ) : (
          <div className="flex flex-center" style={{ width: "100%" }}>
            <img
              src="/images/upload.svg"
              className="inline-block fas"
              alt="upload icon"
            />
            <div
              className="drag-text flex flex-col"
              style={{ textAlign: "left", paddingLeft: 20, flex: 1 }}
            >
              <h3 className="font-semibold">
                Select a file or drag and drop here
              </h3>
              <h2 className="text-dark-gray text-xs">
                JPG, PNG or PDF, file size no more than 10MB
              </h2>
            </div>
            <div className="select-file-button">Select file</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default FileUpload;
