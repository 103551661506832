import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Input from "../Input";

function TravelForm({ parents, handleSubmitParent }) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(parents);
  const [state, updateState] = useState(false);
  const data = formData[0];

  const handleChangeExpress = () => {
    setFormData({
      ...formData[0],
      [0]: {
        ...formData[0],
        date_trip_end: null,
      },
    });
    updateState(!state);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData[0],
      [0]: {
        ...formData[0],
        [name]: value,
      },
    });
  };

  const handleDateChange = (dateType) => (dateString) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = date.getDate().toString().padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    setFormData({
      ...formData[0],
      [0]: {
        ...formData[0],
        [dateType]: formattedDate || "",
      },
    });
    return;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmitParent(formData);
    navigate("/consent/5");
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <h3 className="font-semibold mb-6 text-2xl">Travel Details</h3>
      <div className="mb-6">
        <div className="flex form-container-usual">
          <div>
            <div className="flex flex-col space-y-4">
              <Input
                required
                type="text"
                name="destination"
                value={data.destination}
                label="Destination of the trip"
                placeholder="Please fill in the trip destination"
                onChange={handleInputChange}
              />
              <div className="flex inpt-12 gap-4">
                <div className="flex flex-col w-[100%]">
                  <label for="trip_start">Trip start date</label>
                  <DatePicker
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    dateFormat="dd MMMM yyyy"
                    placeholderText="dd.mm.yyyy"
                    selected={data.date_trip_start}
                    onChange={handleDateChange("date_trip_start")}
                  />
                </div>
                <div className="flex flex-col w-[100%]">
                  {!state && (
                    <>
                      <label for="trip_end">Trip end date (optional)*</label>
                      <DatePicker
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        dateFormat="dd MMMM yyyy"
                        placeholderText="dd.mm.yyyy"
                        selected={data.date_trip_end}
                        onChange={handleDateChange("date_trip_end")}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="flex items-center express-del !m-0 !mt-4 max-lg:!flex-row max-lg:!justify-start max-lg:!items-start">
                <input
                  type="checkbox"
                  checked={state}
                  id="express"
                  className="mr-2"
                  style={{ width: 25, height: 25 }}
                  onChange={handleChangeExpress}
                />
                <label htmlFor="express" className="text-sm ">
                  * If you are unsure of the end date of your child's trip,
                  please select this checkbox.{" "}
                </label>
              </div>
              <Input
                type="textarea"
                withLimit
                name="special_instructions"
                value={data.special_instructions}
                classNames="special_instructions"
                label="Special instructions (optional)**"
                placeholder="Please type here any special instructions (if any). This special instructions will be included in your travel document"
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
      </div>
      <p className="text-sm mb-6 text-center">
        **Only needed if you have any specific instructions. For example travel
        dates, general information etc.
      </p>
      <div className="flex flex-center">
        <NavLink
          to={"/consent/3"}
          className="back-form-button flex flex-center bg-primary-blue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md continue"
        >
          Back
        </NavLink>
        <button
          type="submit"
          className="flex flex-center bg-primary-blue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md continue"
        >
          Continue
        </button>
      </div>
    </form>
  );
}

export default TravelForm;
