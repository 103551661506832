const ImageSizePopup = ({ onClose }) => {
  return (
    <div className="bg-white top-[50%] translate-y-[-50%] p-8 rounded-xl max-w-[996px] absolute w-[100%] translate-x-[-50%] left-[50%] max-lg:bottom-0 max-lg:!rounded-b-none max-lg:!h-[100vh] max-lg:!overflow-auto flex flex-col">
      <h3 className="text-32 font-semibold mb-4 text-center">
        File size should be less than 10MB.
      </h3>
      <img
        src="/images/cross.svg"
        alt="close modal icon"
        className="absolute right-[8px] top-[8px]"
        onClick={onClose}
      />
      <button
        onClick={onClose}
        className="flex flex-center bg-primary-blue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md continue self-center"
      >
        Okay
      </button>
    </div>
  );
};

export default ImageSizePopup;
