const HowItWorksPopup = ({ onClose }) => {
  return (
    <div className="bg-white top-[50%] translate-y-[-50%] p-8 rounded-xl max-w-[996px] absolute w-[100%] translate-x-[-50%] left-[50%] max-lg:bottom-0 max-lg:!rounded-b-none max-lg:!h-[100vh] max-lg:!overflow-auto">
      <h3 className="text-32 font-semibold mb-4">
        How does notarization work?
      </h3>
      <img
        src="/images/cross.svg"
        alt="close modal icon"
        className="absolute right-[8px] top-[8px]"
        onClick={onClose}
      />
      <p className="mb-6 text-base text-dark-gray">
        For more detailed information, please refer to{" "}
        <a className="text-primary-blue" href="/information/11">
          this article.
        </a>
      </p>
      <div className="flex justify-around gap-4 max-lg:flex-col">
        <div className="item-block bg-light-gray p-4 rounded-xl">
          <div className="icon-bg inline-block mb-4">
            <img
              src="/images/1.svg"
              className="mb-3 inline-block fas"
              alt="1 icon"
            />
          </div>
          <h2 className="text-2xl font-semibold mb-2">Identity verification</h2>
          <p>
            The first step involves verifying your identity through a secure
            process to ensure you are who you claim to be.
          </p>
        </div>

        <div className="item-block bg-light-gray p-4 rounded-xl">
          <div className="icon-bg inline-block mb-4">
            <img
              src="/images/2.svg"
              className="mb-3 inline-block fas"
              alt="2 icon"
            />
          </div>
          <h2 className="text-2xl font-semibold mb-2">Notary approval</h2>
          <p>
            A certified notary will review and approve your document, ensuring
            its authenticity and compliance with legal standards.
          </p>
        </div>

        <div className="item-block bg-light-gray p-4 rounded-xl">
          <div className="icon-bg inline-block mb-4">
            <img
              src="/images/3.svg"
              className="mb-3 inline-block fas"
              alt="3 icon"
            />
          </div>
          <h2 className="text-2xl font-semibold mb-2">
            Available for download
          </h2>
          <p>
            Once approved, your notarized document will be available for
            download, ready for use wherever you need it.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowItWorksPopup;
