import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import User from "../../entities/User";

function SignedDigitally() {
  const signatureCanvasRefArr = User?.formData?.map(() => useRef(null));

  const saveSignature = async () => {
    const signatureImages = signatureCanvasRefArr?.map((sig) =>
      sig.current.toDataURL()
    );
    await User.signedDigitally(signatureImages);
  };

  useEffect(() => {
    signatureCanvasRefArr.map((signature, index) => {
      const canvas = signature.current;
      if (canvas !== null) {
        document.fonts.load('50px "Dancing Script", cursive').then(() => {
          const ctx = canvas.getContext("2d");
          ctx.font = "50px Dancing Script, cursive";
          const textMetrics = ctx.measureText(User.formData[index].name);
          const textWidth = Math.ceil(textMetrics.width);
          canvas.width = textWidth + 20;
          ctx.strokeStyle = "lightgray";
          ctx.lineWidth = 1;
          ctx.beginPath();
          ctx.moveTo(-100, 80);
          ctx.lineTo(textWidth + 10, 80);
          ctx.stroke();
          ctx.font = "50px Dancing Script, cursive";
          ctx.fillText(User.formData[index].name, 10, 80);
        });
      }
    });
  }, []);

  return (
    <section className="">
      <div className>
        <div className="flex flex-col items-center mb-6">
          <p
            className="text-3xl text-secondary-black font-bold"
            style={{ marginBottom: 18, textAlign: "center", width: "60%" }}
          >
            Your digitally signature
          </p>
        </div>
      </div>
      <div className="mb-6 parent-container">
        {User.formData.map((user, index) => (
          <div className="flex flex-col" style={{ marginBottom: 20 }}>
            <div
              className="count flex count-signature-dig"
              style={{ marginRight: 40, marginBottom: 20 }}
            >
              <p
                className="font-semibold"
                style={{ color: "gray", fontSize: 18 }}
              >
                {index + 1}.
              </p>
              <p
                className="font-semibold"
                style={{ marginLeft: 30, fontSize: 18 }}
              >
                {user.name}
              </p>
            </div>
            <div className="flex flex-center signature-container">
              <canvas ref={signatureCanvasRefArr[index]} />
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-center">
        <NavLink
          to={"/notarization/payment/complete"}
          className="back-form-button flex flex-center bg-primary-blue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md continue"
        >
          Back
        </NavLink>
        <NavLink
          to={"/notarization/signature/complete?isSigned"}
          onClick={saveSignature}
          style={{ width: 205 }}
          className="flex flex-center bg-primary-blue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md continue"
        >
          Sign and continue
        </NavLink>
      </div>
    </section>
  );
}

export default SignedDigitally;
