import React from "react";

function AboutUS() {
  return (
    <section className="about-us">
      <div className="">
        <div className="top-section">
          <h1 className="text-6xl font-semibold">
            <div>Internationally Valid Travel Consents online</div>
          </h1>
          <p className="font-medium text-xl">
            Welcome to Travelconsent24.com, an online platform created by
            lawyers and Notary Publics with over 10+ year’s experience of
            international law and notarizations.
            <div className="separator" />
            We are here to accommodate all your international travel needs. We
            specialize in creating and notarizing internationally valid travel
            consents, facilitating smoother and more secure journeys for
            families and individuals across the globe.
            <div className="separator" />
          </p>
        </div>
        <div className="first-image">
          <img
            className="h-48 w-full object-cover mb-6 first-image"
            src="/about_placeholder1.png"
            alt="Person writing on a digital tablet, professional setting"
          />
        </div>

        <div className="flex middle-section">
          <img
            src="/about_placeholder2.png"
            alt="Professional man pointing at smartphone screen"
          />
          <div className="flex flex-col right-content">
            <h2 className="text-32 font-semibold mb-8">Why Choose Us?</h2>
            <p className="font-medium text-xl text-light-black">
              The information in "Bulletpoint/Checklist" Expertise: We
              collaborate exclusively with accredited lawyers and notaries
              public who are well-versed in international travel regulations and
              parental consent requirements.
              <br />
              <br />
              <span className="font-bold">Convenience</span>: Our user-friendly
              platform is designed to be accessible at any time and from
              anywhere, allowing you to handle necessary formalities from the
              comfort of your home.
              <br />
              <br />
              <span className="font-bold">Speed</span>: We know that sometimes
              travel plans can be urgent. Our efficient process ensures that
              your travel consent documents are prepared and notarized swiftly.
              <br />
            </p>

            <div className="grid grid-cols-3 last-block mt-8 gap-6 max-sm:grid-cols-1">
              <div className="text-center">
                <span className="text-32 font-semibold text-primary-blue max-lg:text-xl">
                  5+
                </span>
                <p className="font-light text-2xl max-lg:text-base">
                  years in service
                </p>
              </div>
              <div className="text-center">
                <span className="text-32 font-semibold text-primary-blue max-lg:text-xl">
                  100%
                </span>
                <p className="font-light text-2xl max-lg:text-base">
                  user satisfaction
                </p>
              </div>
              <div className="text-center">
                <span className="text-32 font-semibold text-primary-blue max-lg:text-xl">
                  8000+
                </span>
                <p className="font-light text-2xl max-lg:text-base">
                  travel consents
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="payment_placeholder">
          <img
            src="/payment_plaholder.png"
            alt="Person writing on a digital tablet, professional setting"
          />
        </div>
        <div className="bottom-container">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-semibold mb-6">Our Services</h1>
          </div>

          <div className="flex justify-around">
            <div className="item-block">
              <div className="icon-bg inline-block mb-4">
                <i className="fas fa-file-contract icon-form-special"></i>
              </div>
              <h2 className="text-2xl font-semibold mb-2">
                Travel Consent Creation
              </h2>
              <p>
                Get professionally drafted travel consent forms tailored to meet
                specific country regulations and requirements.
              </p>
            </div>

            <div className="item-block">
              <div className="icon-bg inline-block mb-4">
                <i className="fas fa-globe icon-form-special"></i>
              </div>
              <h2 className="text-2xl font-semibold mb-2">
                Online Notarization
              </h2>
              <p>
                With our network of lawyers and certified notaries publics we
                help you online with your documents without the need of visiting
                an office.
              </p>
            </div>

            <div className="item-block">
              <div className="icon-bg inline-block mb-4">
                <i className="fas fa-language icon-form-special"></i>
              </div>
              <h2 className="text-2xl font-semibold mb-2">
                Multi-language Support
              </h2>
              <p>
                We offer services in multiple languages to cater to our diverse
                clientele, ensuring clarity and understanding in all
                communications.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUS;
