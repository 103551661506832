import React from "react";
import { NavLink } from "react-router-dom";
import { ModalHowDigitalWorksContext } from "../../contexts";

function ChooseForm() {
  const { openModalNotarize } = React.useContext(ModalHowDigitalWorksContext);
  console.log("ChooseForm");
  return (
    <section className="flex flex-center flex-col">
      <h3 className="font-semibold mb-6 text-4xl">
        Would you like to notarize your travel consent?
      </h3>
      <p style={{ marginBottom: 40 }} className="font-medium text-dark-gray">
        You can always notarize it later using 'Upload & Notarize' service on
        our website
      </p>

      <div className="flex flex-center flex-col-mobile">
        <NavLink
          to={"/checkout"}
          onClick={() => {
            localStorage.setItem("isNotarized", false);
            console.log("isNotarized: false");
          }}
          style={{ width: "auto" }}
          className="back-form-button flex flex-center border-gray-33 text-white font-bold py-2 px-4 rounded-md continue"
        >
          Skip to checkout
        </NavLink>
        <NavLink
          onClick={() => {
            localStorage.setItem("isNotarized", true);
            console.log("isNotarized: true");
          }}
          to={"/notarization/identity"}
          style={{ width: "auto" }}
          className="flex flex-center bg-primary-blue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md continue"
        >
          Notarize travel consent
        </NavLink>
      </div>
      <span
        style={{ marginTop: 20, marginBottom: 10, cursor: "pointer" }}
        className="text-primary-blue font-medium"
        onClick={openModalNotarize}
      >
        How does it work?
      </span>
    </section>
  );
}

export default ChooseForm;
