import fetch from "../helpers/fetch";

export default class Payment {
  _consent = 40;
  _notarization = 40;
  _express_delivery = 5;
  _total = {};
  _prices = {
    express_delivery: "price_1OwJcvFb7TIrNtiq63i7t98u",
    notarization: "price_1OwJchFb7TIrNtiqFRDx7UuB",
    consent: "price_1OwJc3Fb7TIrNtiqFETpytJv",
  };

  constructor(isExpress) {
    const queryParams = new URLSearchParams(location.search);
    const isNeededNotarization = queryParams.get("isNeededNotarization") === "";
    const isOnlyNotarization = queryParams.get("isOnlyNotarization") === "";

    if (isOnlyNotarization) this._total.notarization = this._notarization;
    else this._total.consent = this._consent;
    if (isNeededNotarization) this._total.notarization = this._notarization;
    if (isExpress) this._total.express_delivery = this._express_delivery;
  }

  get price() {
    return {
      consent: this._consent,
      notarization: this._notarization,
      express_delivery: this._express_delivery,
    };
  }

  get total() {
    return this._total;
  }

  get subtotal() {
    return Object.entries(this._total).reduce((acc, [key, price]) => {
      return acc + (key === "express_delivery" ? 0 : price);
    }, 0);
  }

  get amount() {
    let sum = 0;
    Object.values(this._total).map((val) => (sum += val));
    return sum;
  }

  get stripeData() {
    const products = Object.keys(this.total);
    const queryParams = new URLSearchParams(location.search);
    const isOnlyNotarization = queryParams.get("isOnlyNotarization") === "";

    let baseSuccessURL = "/notarization/payment/complete";

    if (isOnlyNotarization) baseSuccessURL = "/notarization/signature/complete";

    return {
      lineItems: products.map((product) => ({
        price: this._prices[product],
        quantity: 1,
      })),
      mode: "payment",
      successUrl:
        process.env.REACT_APP_URL +
        baseSuccessURL +
        "?session_id={CHECKOUT_SESSION_ID}",
      cancelUrl: process.env.REACT_APP_URL + "/checkout",
    };
  }

  toggleExpressDelivery = () => {
    this._total.express_delivery = this._total.express_delivery
      ? 0
      : this._express_delivery;
  };
}
