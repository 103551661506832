import React, { useState } from "react";
import User from "../../entities/User";
import { useNavigate } from "react-router-dom";

function Finish({ parents, handleSubmitParent }) {
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const isCustomTravelConsent = queryParams.get("isCustomTravelConsent") === "";

  const [formData, setFormData] = useState(parents);
  const data = formData[0];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData[0],
      [0]: {
        ...formData[0],
        [name]: value,
      },
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    User.email = data.email;
    if (isCustomTravelConsent) {
      await User.uploadTravelConsent();
      navigate("/notarization/identity/custom?isOnlyNotarization");
    } else {
      handleSubmitParent(formData, e);
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div className>
        <div className="flex flex-col items-center mb-6">
          <i className="fas fa-envelope text-primary-blue text-6xl mb-3"></i>
          <h2 className="text-32 text-secondary-gray font-semibold mb-2 text-center">
            {isCustomTravelConsent
              ? "Thank you! Consent has been uploaded"
              : "Thank you! The Travel Consent will be issued within a few minutes."}
          </h2>
          <p
            className="text-32 text-secondary-black font-semibold"
            style={{ marginBottom: 18 }}
          >
            Enter your email address
          </p>
          <p className="text-base text-dark-gray mb-6 font-medium">
            We will use it to send you the documents later on.
          </p>
        </div>

        <div>
          <div className="mb-6 flex flex-center">
            <input
              required
              type="email"
              name="email"
              value={data.email}
              onChange={handleInputChange}
              placeholder="Email address"
              style={{ maxWidth: 588 }}
              className="w-full p-3 border-2 border-gray-300 rounded-xl text-center"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-center">
        <button
          type="submit"
          className="flex flex-center bg-primary-blue hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md continue"
          style={{ width: "max-content" }}
        >
          Proceed to checkout
        </button>
      </div>
    </form>
  );
}

export default Finish;
