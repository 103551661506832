import React, { useState, useRef, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  getCurrentStage,
  getStageClass,
  isCustomTravelConsent,
  isFinishConsent,
  parse,
  stagesToScroll,
} from "../../helpers";
import ParentForm from "./ParentForm";
import MinorForm from "./MinorForm";
import AdultForm from "./AdultForm";
import TravelForm from "./TravelForm";
import PreviewForm from "./PreviewForm";
import Finish from "./Finish";
import { useNavigate } from "react-router-dom";
import User from "../../entities/User";
import UserTravelConsent from "./UserTravelConsent";
import { alertError } from "../../helpers/notifcation";
import ScrollToTop from "../../helpers/scrollHook";

function Form() {
  const scrollStages = useRef(null);
  const navigate = useNavigate();

  const [parents, setParents] = useState(() => {
    const saved = User.formData;
    return saved || [{}];
  });
  const location = useLocation();

  useEffect(() => {
    if (scrollStages.current) {
      scrollStages.current.scrollLeft = stagesToScroll[getCurrentStage()];
    }
  }, [location]);

  const handleSubmitParent = (formData) => {
    let data;
    const isEmptyFormDataSecond =
      formData[1] && Object.keys(formData[1]).length === 0;

    if (formData[1] && !isEmptyFormDataSecond) {
      data = [formData[0], formData[1]];
    } else if ((!formData[1] || isEmptyFormDataSecond) && parents[1]) {
      data = [formData[0], parents[1]];
    } else {
      data = [formData[0]];
    }

    setParents(data);
    User.formData = data;
  };

  const handleSubmitForm = async (data, e) => {
    e.preventDefault();
    try {
      await User.save();
      navigate("/notarization/choose");
    } catch (e) {
      alertError("Something went wrong");
    }
  };

  const handleStoreTravelConsent = async (file) => {
    User.travelConsentFile = file;
  };

  return (
    <section className="form-section">
      <div className="w-full max-w-3xl mx-auto bg-white p-8 my-10 form-title">
        {!isFinishConsent() && (
          <div>
            <h2 className="text-6xl font-bold mb-6">Create travel consent</h2>
            <p className="text-1xl mb-8">
              {`${
                getCurrentStage() !== 5
                  ? "We will need some information from you"
                  : "Check the information you provided"
              } before we can generate your
              travel consent. You will be able to notarize it after consent is
              generated.`}
            </p>
          </div>
        )}
        {isCustomTravelConsent() && (
          <div className="flex flex-center flex-col">
            <h2 className="text-6xl font-bold mb-6">Notarize travel consent</h2>
            <p className="text-1xl mb-8" style={{ width: "80%" }}>
              We will ask you to upload the travel consent document and verify
              your identity in order to notarize it.
            </p>
          </div>
        )}
      </div>
      <div
        className={`rounded-xl shadow-md form ${
          isFinishConsent() ? "finish_form" : ""
        }`}
      >
        {!isFinishConsent() && (
          <div className="overflow-stages" ref={scrollStages} id="overflow">
            <div className="flex justify-between items-center p-4 form-stages">
              <div className="flex items-center">
                <div className="flex flex-col items-center progress-item">
                  <div
                    className={`${getStageClass(
                      1
                    )} flex items-center justify-center w-14 h-14 stage text-white font-bold rounded-full`}
                  >
                    1
                  </div>
                  <div className="text-base text-center mt-2">
                    Parent/Guardian
                  </div>
                </div>
              </div>
              <div className={`progress-item-separator ${getStageClass(1)}`} />
              <div className="flex items-center">
                <div className="flex flex-col items-center progress-item">
                  <div
                    className={`${getStageClass(
                      2
                    )} flex items-center justify-center w-14 h-14 stage text-white font-bold rounded-full`}
                  >
                    2
                  </div>
                  <div className="text-base text-center mt-2">Minor</div>
                </div>
              </div>
              <div className={`progress-item-separator ${getStageClass(2)}`} />
              <div className="flex items-center">
                <div className="flex flex-col items-center progress-item">
                  <div
                    className={`${getStageClass(
                      3
                    )} flex items-center justify-center w-14 h-14 stage text-white font-bold rounded-full`}
                  >
                    3
                  </div>
                  <div className="text-base text-center mt-2">
                    Accompanying Adult
                  </div>
                </div>
              </div>
              <div className={`progress-item-separator ${getStageClass(3)}`} />
              <div className="flex items-center">
                <div className="flex flex-col items-center progress-item">
                  <div
                    className={`${getStageClass(
                      4
                    )} flex items-center justify-center w-14 h-14 stage text-white font-bold rounded-full`}
                  >
                    4
                  </div>
                  <div className="text-base text-center mt-2">
                    Travel Details
                  </div>
                </div>
              </div>
              <div className={`progress-item-separator ${getStageClass(4)}`} />
              <div className="flex items-center">
                <div className="flex flex-col items-center progress-item">
                  <div
                    className={`${getStageClass(
                      5
                    )} flex items-center justify-center w-14 h-14 stage text-white font-bold rounded-full`}
                  >
                    5
                  </div>
                  <div className="text-base text-center mt-2">Preview</div>
                </div>
              </div>
            </div>
          </div>
        )}
        <ScrollToTop />

        <Routes>
          <Route
            path="/1"
            element={
              <ParentForm
                parents={parents}
                setParents={setParents}
                handleSubmitParent={handleSubmitParent}
              />
            }
          />
          <Route
            path="/2"
            element={
              <MinorForm
                parents={parents}
                handleSubmitParent={handleSubmitParent}
              />
            }
          />
          <Route
            path="/3"
            element={
              <AdultForm
                parents={parents}
                handleSubmitParent={handleSubmitParent}
              />
            }
          />
          <Route
            path="/4"
            element={
              <TravelForm
                parents={parents}
                handleSubmitParent={handleSubmitParent}
              />
            }
          />
          <Route
            path="/5"
            element={
              <PreviewForm
                parents={parents}
                handleSubmitParent={handleSubmitParent}
              />
            }
          />
          <Route
            path="/finish"
            element={
              <Finish parents={parents} handleSubmitParent={handleSubmitForm} />
            }
          />
          <Route
            path="/travel/form"
            element={
              <UserTravelConsent
                handleStoreTravelConsent={handleStoreTravelConsent}
              />
            }
          />
        </Routes>
      </div>

      {!isFinishConsent() && (
        <div className="w-full bg-white p-4 form-bottom-section">
          <div className="flex justify-between items-center max-w-3xl mx-auto">
            <div className="flex items-center">
              <img
                src="/images/user.svg"
                className="mr-2 inline-block fas w-[32px]"
                alt="user icon"
              />
              Notarization by demand
            </div>
            <div className="flex items-center">
              <img
                src="/images/users.svg"
                className="mr-2 inline-block fas w-[32px]"
                alt="users icon"
              />
              100% user satisfaction
            </div>
            <div className="flex items-center">
              <img
                src="/images/lock.svg"
                className="mr-2 inline-block fas w-[32px]"
                alt="lock icon"
              />
              Fast & Secure
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default Form;
