import React, { useEffect, useState } from "react";
import User from "../entities/User";
import { NavLink } from "react-router-dom";
import VideoPlayer from "./VideoPlayer";

const icons = [
  "/icons/icon1.svg",
  "/icons/icon2.svg",
  "/icons/icon3.svg",
  "/icons/icon4.svg",
  "/icons/icon5.svg",
  "/icons/icon6.svg",
  "/icons/icon7.svg",
  "/icons/icon8.svg",
  "/icons/icon9.svg",
  "/icons/icon10.svg",
  "/icons/icon11.svg",
  "/icons/icon12.svg",
  "/icons/icon13.svg",
  "/icons/icon14.svg",
  "/icons/icon15.svg",
  "/icons/icon16.svg",
  "/icons/icon17.svg",
];

function InformationPage() {
  const [information, setInformation] = useState(null);

  useEffect(() => {
    User.getInformation(location.pathname.split("/")[2]).then((res) => {
      const dataParsed = JSON.parse(res.data.text);
      const text = dataParsed.filter((el) => "title" in el);

      const iconsArray = dataParsed
        .filter((el) => !("title" in el))
        .map((el) => Object.values(el))
        .flat();
      return setInformation({
        text: text,
        icons: iconsArray,
        videos: res.data.videos,
      });
    });
  }, []);

  if (!information)
    return <div style={{ width: "100%", height: "1000px" }}></div>;

  return (
    <section className="information-page text-light-black font-medium">
      <div className="p-4">
        <div className="grid grid-cols-2 max-lg:grid-cols-1">
          <h1 className="text-[64px]/[64px] font-semibold max-w-[480px]">
            {information.text[0].title}
          </h1>
          <div className="pt-10">
            <p className="text-xl mb-4">{information.text[0].description}</p>
            <div className="flex gap-2 max-sm:flex-col">
              <NavLink
                to={"/consent/1"}
                className="default-link bg-white text-secondary-black font-bold py-2 px-6 rounded white-button-blue-transition inline-block"
              >
                Create travel consent
              </NavLink>
              <NavLink
                to={"/consent/travel/form"}
                className="default-link bg-white text-secondary-black font-bold py-2 px-6 rounded white-button-blue-transition inline-block"
              >
                Upload & Notarize
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className="separator-bl"></div>

      <div className="bg-white p-4 sector-2">
        <div className="">
          <h2 className="font-semibold text-5xl mb-5" style={{ fontSize: 40 }}>
            {information.text[1].title}
          </h2>
          <div className="grid grid-cols-3 mb-10">
            <p className="col-span-2 text-xl max-lg:col-span-3">
              {information.text[1].description}
            </p>
          </div>
          <div className="four-blocks">
            <div className="full-width-mobile">
              <h3 className="text-32 font-semibold">
                {information.text[2].title}
              </h3>
              <p className="text-xl">{information.text[2].description}</p>
            </div>
            <div className="full-width-mobile">
              <h3 className="text-32 font-semibold">
                {information.text[3].title}
              </h3>
              <p className="text-xl">{information.text[3].description}</p>
            </div>
            <div className="full-width-mobile">
              <h3 className="text-32 font-semibold">
                {information.text[4].title}
              </h3>
              <p className="text-xl">{information.text[4].description}</p>
            </div>
            <div className="full-width-mobile">
              <h3 className="text-32 font-semibold">
                {information.text[5].title}
              </h3>
              <p className="text-xl">{information.text[5].description}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-light-gray section pb-[120px]">
        <div className="p-4 pl-[100px] pr-[100px] bottom-home-section rounded-3xl bg-light-gray">
          <div className="container text-white">
            <h2 className="text-5xl font-bold mb-4 title-bottom-home-section">
              Create a legally valid Travel Consent within a few minutes.
            </h2>
            <div className="flex space-x-4 choose-section-12">
              <NavLink
                to={"/consent/1"}
                className="bg-white text-secondary-black px-6 py-2 rounded-md shadow default-link white-button-blue-transition font-semibold"
              >
                Create travel consent
              </NavLink>
              <NavLink
                to={"/consent/travel/form"}
                className="border border-white text-white px-6 py-2 rounded-md shadow default-link blue-button-white-transition font-semibold"
              >
                Upload & Notarize
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="bg-white">
          {!!information.videos[0] && (
            <div className="left-block">
              <div className="left-ds">
                <h1
                  className="text-2xl font-bold mb-4"
                  style={{ fontSize: 35 }}
                >
                  {information.text[6].title}
                </h1>
                <p className="text-xl">{information.text[6].description}</p>
              </div>
              <div className="right-ds">
                <VideoPlayer video={information.videos[0]} />
              </div>
            </div>
          )}
          {!!information.videos[1] && (
            <div className="right-block">
              <div className="right-ds">
                <VideoPlayer video={information.videos[1]} />
              </div>
              <div className="left-ds">
                <h1
                  className="text-2xl font-bold mb-4"
                  style={{ fontSize: 35 }}
                >
                  {information.text[7].title}
                </h1>
                <p className="text-xl">{information.text[7].description}</p>
              </div>
            </div>
          )}

          <div className="bottom-section">
            <h2
              className="font-semibold text-5xl mb-5"
              style={{ fontSize: 35 }}
            >
              {information.text[8].title}
            </h2>
            <div className="grid grid-cols-3 mb-8">
              <p className="col-span-2 mb-5 text-xl max-lg:col-span-3">
                {information.text[8].description}
              </p>
            </div>
            <div className="flex flex-col-mobile gap-4">
              <div className="p-4 rounded-3xl bg-white">
                <img
                  src={icons[information.icons[0]] || "/images/time.svg"}
                  className="mb-3 inline-block fas"
                  alt="clock icon"
                />
                <h3 className="font-semibold text-2xl mb-4">
                  {information.text[9].title}
                </h3>
                <p className="text-xl">{information.text[9].description}</p>
              </div>
              <div className="p-4 rounded-3xl bg-white">
                <img
                  src={icons[information.icons[1]] || "/images/circle-dot.svg"}
                  className="mb-3 inline-block fas"
                  alt="circle icon"
                />
                <h3 className="font-semibold text-2xl mb-4">
                  {information.text[10].title}
                </h3>
                <p className="text-xl">{information.text[10].description}</p>
              </div>
              <div className="p-4 rounded-3xl bg-white">
                <img
                  src={icons[information.icons[2]] || "/images/lock.svg"}
                  className="mb-3 inline-block fas"
                  alt="lock icon"
                />
                <h3 className="font-semibold text-2xl mb-4">
                  {information.text[11].title}
                </h3>
                <p className="text-xl">{information.text[11].description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InformationPage;
