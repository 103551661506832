import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import Input from "../Input";
import "react-datepicker/dist/react-datepicker.css";

function MinorForm({ parents, handleSubmitParent }) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(parents);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData[0],
      [0]: {
        ...formData[0],
        [name]: value,
      },
    });
  };

  const handleDateChange = (dateString) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = date.getDate().toString().padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    setFormData({
      ...formData[0],
      [0]: {
        ...formData[0],
        minor_date_of_birth: formattedDate || "",
      },
    });
    return;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmitParent(formData);
    navigate("/consent/3");
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <h3 className="font-semibold mb-6 text-2xl">Minor</h3>
      <div className="mb-6">
        <div className="flex form-container-usual">
          <div>
            <div className="flex flex-col space-y-4">
              <div className="flex inpt-12 gap-4">
                <Input
                  required
                  type="text"
                  name="minor_name"
                  value={formData[0].minor_name}
                  placeholder="Please provide the child's full name"
                  label="Child's name"
                  onChange={handleInputChange}
                />

                <div className="flex flex-col w-[100%]">
                  <label for="minor_date_of_birth">
                    Add the child's birthday here
                  </label>
                  <DatePicker
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormat="dd MMMM yyyy"
                    placeholderText="dd.mm.yyyy"
                    selected={formData[0].minor_date_of_birth}
                    onChange={handleDateChange}
                  />
                </div>
              </div>
              <Input
                required
                type="text"
                name="minor_passport"
                value={formData[0].minor_passport}
                placeholder="Please provide the child's passport number"
                onChange={handleInputChange}
                label="Passport number"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-center">
        <NavLink
          to={"/consent/1"}
          className="back-form-button flex flex-center bg-primary-blue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md continue"
        >
          Back
        </NavLink>
        <button
          type="submit"
          className="flex flex-center bg-primary-blue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md continue"
        >
          Continue
        </button>
      </div>
    </form>
  );
}

export default MinorForm;
