import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../Input";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function ParentForm({ parents, handleSubmitParent, setParents }) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(parents);

  const handleInputChange = (index) => (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [index]: {
        ...formData[index],
        [name]: value,
      },
    });
  };

  const handleDateChange = (index) => (dateString) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = date.getDate().toString().padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;

    setFormData({
      ...formData,
      [index]: {
        ...formData[index],
        parent_birthday: formattedDate || "",
      },
    });
    return;
  };

  useEffect(() => {
    if (parents[1]) setFormData({ ...formData, [1]: parents[1] });
    else setFormData({ [0]: formData[0] });
  }, [parents]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmitParent(formData);
    navigate("/consent/2");
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <h3 className="font-semibold mb-6 text-2xl">
        Parent/Guardian granting consent
      </h3>
      {Object.keys(formData).map((_, index) => (
        <div className="mb-6 parent-container" key={index}>
          <div className="flex">
            <div className="count" style={{ marginRight: 40 }}>
              {index + 1}.
            </div>
            <div style={{ flexGrow: 1 }}>
              <div className="flex flex-col space-y-4">
                <div className="flex inpt-12 gap-4">
                  <Input
                    required
                    type="text"
                    name="name"
                    value={formData[index].name}
                    onChange={handleInputChange(index)}
                    label="Parent or Guardian's Name"
                    placeholder="Type full name here"
                  />
                  <div className="flex flex-col w-[100%]">
                    <label for="date_of_birth">
                      Date of Parent/Guardian birth
                    </label>
                    <DatePicker
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      dateFormat="dd MMMM yyyy"
                      placeholderText="dd.mm.yyyy"
                      selected={formData[index].parent_birthday}
                      onChange={handleDateChange(index)}
                    />
                  </div>
                </div>
                <div className="flex inpt-12 gap-4">
                  <Input
                    required
                    type="text"
                    name="parent_passport"
                    value={formData[index].passport_number}
                    placeholder="Please provide the parent's passport number"
                    onChange={handleInputChange(index)}
                    label="Passport Number"
                  />
                  <Input
                    required
                    type="text"
                    name="relationship"
                    value={formData[index].relationship}
                    placeholder="Indicate how you are related to the child"
                    onChange={handleInputChange(index)}
                    label="Relationship to the child"
                  />
                </div>
                <Input
                  required
                  type="number"
                  name="phone"
                  value={formData[index].phone}
                  placeholder="Type your phone number here"
                  label="Phone number"
                  onChange={handleInputChange(index)}
                />
                <Input
                  required
                  name="address"
                  placeholder="Please provide your address"
                  value={formData[index].address}
                  onChange={handleInputChange(index)}
                  label="Address"
                  type="textarea"
                />
              </div>
              {parents.length < 2 ? (
                <button
                  className="text-primary-blue mt-4"
                  onClick={() => setParents([...parents, {}])}
                >
                  + Add second parent/guardian
                </button>
              ) : (
                ""
              )}
              {parents.length === 2 && index === 1 ? (
                <button
                  className="text-red-500 mt-4"
                  onClick={() => setParents([formData[0]])}
                >
                  - Remove second parent/guardian
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ))}

      <div className="flex flex-center">
        <button
          type="submit"
          className="flex flex-center bg-primary-blue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md continue"
        >
          Continue
        </button>
      </div>
    </form>
  );
}

export default ParentForm;
