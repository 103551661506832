import {toast} from "react-toastify";

export function alertError(message) {
  return toast.error(message, {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    theme: "dark",
  });
}

export function alertSuccess(message) {
  return toast.success(message, {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    theme: "dark",
  });
}