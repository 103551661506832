const Input = ({
  name,
  label,
  type,
  placeholder,
  onChange,
  value,
  classNames = "",
  required = false,
  withLimit = false,
}) => {
  return (
    <div className="flex flex-col w-[100%] relative">
      <label htmlFor={name}>{label}</label>
      {type === "textarea" ? (
        <>
          <textarea
            type="text"
            maxLength={withLimit ? 200 : "unset"}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            required={required}
            value={value}
            className={`border-2 rounded-xl p-2 !m-0 ${classNames}`}
          />
          {withLimit && (
            <p className="absolute bottom-[-20px] right-2 text-secondary-gray">
              {value?.length || 0}/200
            </p>
          )}
        </>
      ) : (
        <input
          name={name}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          required={required}
          value={value}
          className={`border-2 rounded-xl p-2 !m-0 ${classNames}`}
        />
      )}
    </div>
  );
};

export default Input;
