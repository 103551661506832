import React, { useState } from "react";
import Payment from "../entities/Payment";
import { Tooltip } from "react-tooltip";
import { useStripe } from "@stripe/react-stripe-js";
import { Link } from "react-router-dom";
import User from "../entities/User";

function CheckoutForm() {
  const stripe = useStripe();
  const [state, updateState] = useState(false);
  const payment = new Payment(state);

  const handleChangeExpress = () => {
    payment.toggleExpressDelivery();
    updateState(!state);
    User.formData = User.formData.map((el, index) =>
      index !== 0 ? el : { ...el, isExpress: !state }
    );
  };

  const handlePay = async () => {
    await stripe.redirectToCheckout(payment.stripeData);
  };

  return (
    <div className="container mx-auto my-10 bg-white text-secondary-black">
      <h1 className="text-6xl font-bold mb-6">Checkout</h1>

      <div
        className="flex justify-between payment-block"
        style={{ marginLeft: "-1rem" }}
      >
        <div style={{ width: "70%" }} className="w-1/2 p-4 block-1">
          {payment.total.consent && (
            <div
              className="mb-4 p-4 bg-light-gray rounded-3xl"
              style={{ display: "flex", padding: "25px" }}
            >
              <div style={{ flex: 1 }}>
                <h2 className="font-semibold text-2xl">Travel Consent Form</h2>
                <p
                  className="my-2 text-1xl text-balance text-dark-gray text-base"
                  style={{ maxWidth: "800px" }}
                >
                  Internationally valid Travel Consent - You will receive your
                  personalized consent directly ready for use.
                </p>
              </div>
              <p
                className="font-bold flex-center flex text-2xl"
                style={{ paddingRight: "5%", fontSize: 23 }}
              >
                ${payment.price.consent}.00
              </p>
            </div>
          )}
          {payment.total.notarization && (
            <div
              className="mb-4 p-4 bg-light-gray rounded-3xl relative"
              style={{ display: "flex", padding: "25px" }}
            >
              <div style={{ flex: 1 }}>
                <h2 className="font-semibold text-2xl">
                  Notarization Services
                </h2>
                <p
                  className="my-2 text-1xl text-balance text-dark-gray text-base"
                  style={{ maxWidth: "360px" }}
                >
                  Service provided by our notary that verify your identity and
                  notarize the document.
                </p>
              </div>
              <p
                className="font-bold flex-center flex text-2xl"
                style={{ paddingRight: "5%", fontSize: 23 }}
              >
                ${payment.price.notarization}.00
              </p>
              {payment.total.consent && (
                <Link
                  to="/checkout"
                  className="absolute right-[10px] top-[50%] translate-y-[-50%] cursor-pointer"
                >
                  <img src="/images/cross.svg" alt="Delete notarization icon" />
                </Link>
              )}
            </div>
          )}
        </div>

        <div
          style={{
            flex: 1,
            margin: "1rem",
            marginBottom: 50,
            marginLeft: 0,
            padding: "1.5rem",
            display: "flex",
            flexDirection: "column",
          }}
          className="w-1/3 bg-light-gray rounded p-4 stripe-box"
        >
          <div style={{ flex: 1, borderBottom: "none" }}>
            <h2 className="font-semibold text-1xl">Summary</h2>
            <div className="flex justify-between mb-2 text-dark-gray">
              <span>Subtotal</span>
              <span>${payment.subtotal}.00</span>
            </div>

            {!!payment.total.express_delivery && (
              <div className="flex justify-between mb-4">
                <span>Express delivery</span>
                <span>${payment.price.express_delivery}.00</span>
              </div>
            )}
            <div
              className="flex justify-between font-bold mb-4"
              style={{ border: "none" }}
            >
              <span>Total</span>
              <span>${payment.amount}.00</span>
            </div>
          </div>
          <button
            className="w-full bg-black text-white p-2 rounded black-button !h-[max-content]"
            style={{ width: "100%", height: 48 }}
            onClick={handlePay}
          >
            Continue with Payment
          </button>
        </div>
      </div>
      {!!payment.total.notarization && (
        <div
          className="flex items-center mb-4 express-del"
          style={{ marginLeft: 20 }}
        >
          <input
            type="checkbox"
            checked={state}
            id="express"
            className="mr-2"
            style={{ width: 25, height: 25 }}
            onChange={handleChangeExpress}
          />
          <label htmlFor="express" className="text-sm">
            Express delivery
          </label>
          <i className="fas fa-info text-primary-blue mr-2 express-delivary-icon" />
          <Tooltip
            className="express-tooltip shadow-lg"
            style={{
              padding: 15,
              borderRadis: 30,
              width: 434,
              backgroundColor: "white",
              color: "black",
            }}
            anchorSelect=".express-delivary-icon"
            place="bottom-start"
          >
            If you need your document urgently select this option and receive
            your order within 1-2 hours
          </Tooltip>
        </div>
      )}

      <div className="flex items-center justify-center mt-6 info-checkout-container">
        <div className="flex items-center flex-col">
          <img
            src="/images/shield.svg"
            className="mb-6 inline-block fas"
            alt="card protected icon"
          />
          <span className="font-semibold text-xl">Verified secure payment</span>
        </div>
        <span className="checkout-separator" />
        <div className="flex items-center flex-col">
          <img
            src="/images/bill.svg"
            className="mb-6 inline-block fas"
            alt="dollar bill icon"
          />
          <span className="font-semibold text-xl">Money-back guarantee</span>
        </div>
        <span className="checkout-separator" />
        <div className="flex items-center flex-col">
          <img
            src="/images/lock.svg"
            className="mb-6 inline-block fas"
            alt="lock icon"
          />
          <span className="font-semibold text-xl">
            Your information is secure
          </span>
        </div>
      </div>
    </div>
  );
}

export default CheckoutForm;
