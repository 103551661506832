import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { isFullyFormPage, parse } from "../helpers";
import User from "../entities/User";

function Footer() {
  const [informationPages, setInformationPages] = useState([]);

  React.useEffect(() => {
    User.getAllInformation().then((res) => setInformationPages(res.data));
  }, []);

  return (
    <footer className="bg-secondary-black text-white section mt-[auto]">
      {!isFullyFormPage() && (
        <div className="p-4 section bottom-home-section">
          <div className="container text-white">
            <h2 className="text-5xl font-bold mb-4 title-bottom-home-section">
              Create a legally valid Travel Consent within a few minutes.
            </h2>
            <div className="flex space-x-4 choose-section-12">
              <NavLink
                to={"/consent/1"}
                className="bg-white text-secondary-black px-6 py-2 rounded-md shadow default-link white-button-blue-transition font-semibold"
              >
                Create travel consent
              </NavLink>
              <NavLink
                to={"/consent/travel/form"}
                className="border border-white text-white px-6 py-2 rounded-md shadow default-link blue-button-white-transition font-semibold"
              >
                Upload & Notarize
              </NavLink>
            </div>
          </div>
        </div>
      )}
      <div className="container px-4 py-8" style={{ paddingBottom: 0 }}>
        <div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8"
          style={{ paddingBottom: 50 }}
        >
          <div className="first-section">
            <p className="text-sm text-secondary-gray mt-8">
              Create, notarize, and download legally valid Travel Consent
              online.
            </p>
          </div>
          <div className="second-section">
            <h3 className="font-semibold mb-2 text-light-gray">Services</h3>
            <ul className="text-sm space-y-1 text-secondary-gray">
              <li>
                <NavLink to={"/consent/1"} className="hover:underline">
                  Create consent
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"/consent/travel/form"}
                  className="hover:underline"
                >
                  Upload & Notarize
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="second-section">
            <h3 className="font-semibold mb-2 text-light-gray">
              Travel Consent
            </h3>
            <ul className="text-sm space-y-1 text-secondary-gray">
              <li>
                <NavLink to={"/about"} className="hover:underline">
                  About us
                </NavLink>
              </li>
              <li>
                <NavLink to={"/blogs"} className="hover:underline">
                  Blog
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="second-section">
            <h3 className="font-semibold mb-2 text-light-gray">Contact us</h3>
            <a
              href="mailto:example@info@travelconsent24.com"
              className="text-sm text-secondary-gray"
            >
              info@travelconsent24.com
            </a>
          </div>
        </div>
        <div className="border-t border-gray-700 mt-8 pt-4 flex flex-col md:flex-row justify-between items-center footer_bottom">
          <div className="mb-4 md:mb-0 text-secondary-gray font-medium text-xs">
            © 2024 Travel Consent. All rights reserved.
          </div>
          <div className="flex items-center text-secondary-gray text-xs font-medium">
            <NavLink to={"/terms"} style={{ marginRight: 8 }}>
              Terms & Conditions
            </NavLink>
            <span className="inline-block w-1 h-1 bg-secondary-gray rounded-full"></span>
            <NavLink to={"/privacy"} style={{ marginLeft: 8 }}>
              {" "}
              Privacy Policy
            </NavLink>
          </div>
          {/* <div className="flex space-x-4">
                  </div> */}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
