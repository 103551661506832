import React, { useEffect, useState } from "react";
import { ModalContext } from "../contexts";
import User from "../entities/User";
import { convertISOToDate } from "../helpers";
import { NavLink, useLocation } from "react-router-dom";

function BlogItemPage() {
  const location = useLocation();

  const [isScrolled, setIsScrolled] = useState(false);
  const [blog, setBlog] = useState(null);
  const [blogs, setBlogs] = useState([]);
  const [isAbsolute, setIsAbsolute] = useState(null);
  const path = window.location.pathname.split("/");
  const hash = window.location.hash;
  const currentBlogId = path[2];
  const lastBlog = blogs[blogs.length - 1];
  const nextBlog =
    blogs[blogs.findIndex((blog) => blog.id === Number(currentBlogId)) + 1];

  const scrollListener = () => {
    if (document.getElementById("left-side")) {
      window.scrollY > document.getElementById("left-side").offsetTop - 20
        ? setIsScrolled(true)
        : setIsScrolled(false);
    }

    if (!blog?.text?.length) {
      return;
    }
    window.scrollY >
    document.getElementById(`section${blog.text.length - 1}`).offsetTop + 120
      ? setIsAbsolute(true)
      : setIsAbsolute(null);
  };

  window.addEventListener("scroll", scrollListener);

  useEffect(() => {
    User.destroy();
    User.getOneBlog(path[2]).then((res) => setBlog(res.data));
    User.getAllBlogs().then((res) => setBlogs(res.data));
    return () => {
      window.removeEventListener("scroll", scrollListener);
    };
  }, []);

  if (!blog) return <></>;

  return (
    <section className="faq-page terms">
      <div className="title-faq">
        <p className="updated-date">{convertISOToDate(blog.createdAt)}</p>
        <h1 className="font-semibold" style={{ paddingTop: 10 }}>
          {blog.title}
        </h1>
        <img
          src={blog.image}
          style={{
            objectFit: "cover",
            height: "auto",
            width: "100%",
            borderRadius: 20,
          }}
        ></img>
      </div>
      <div className="info-page">
        <div className="left-side" id="left-side">
          <div
            className={`anchor-block ${isScrolled ? "fixed" : ""} ${
              isAbsolute ? "absolute-123" : ""
            }`}
          >
            <h3 className="font-semibold">ON THIS PAGE</h3>
            {blog.text.map((t, index) => (
              <a
                href={`#section${index + 1}`}
                className={hash === `#section${index + 1}` ? "active" : ""}
              >
                {t.title}
              </a>
            ))}
          </div>
        </div>
        <div className="right-side terms-page">
          {blog.text.map((t, index) => (
            <>
              <h2 className="font-semibold" id={`section${index + 1}`}>
                {t.title}
              </h2>
              <p>{t.desc}</p>
            </>
          ))}
          {!!nextBlog && (
            <div className="next">
              Next article:{" "}
              <NavLink to={`/blog/${nextBlog.id}`}>{nextBlog.title}</NavLink>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default BlogItemPage;
