import React from "react";
import { NavLink } from "react-router-dom";

function IdentityComplete() {
  const queryParams = new URLSearchParams(location.search);
  const isOnlyNotarization = queryParams.get("isOnlyNotarization") === "";

  return (
    <section className="">
      <div className>
        <div className="flex flex-col items-center mb-6">
          <img
            src="/images/user.svg"
            className="mb-3 inline-block fas"
            alt="user icon"
          />
          <h2 className="text-3xl text-secondary-gray font-bold mb-2">
            Almost there.
          </h2>
          <p
            className="text-3xl text-secondary-black font-bold text-center-mobile"
            style={{ marginBottom: 18 }}
          >
            Documents have been sent for verification.
          </p>
          <p
            className="text-base text-dark-gray mb-6"
            style={{ marginBottom: 18, width: "60%", textAlign: "center" }}
          >
            Our notary will notarize your travel consent as soon as your
            identity is verified and payment for the service is received.
          </p>
          <p className="text-base text-dark-gray mb-6">
            Usually this process takes 0-2 hours. Contact
            info@travelconsent24.com for any questions.
          </p>
          <p className="text-base text-dark-gray mb-6">Thank you!</p>
        </div>
      </div>
      <div className="flex flex-center">
        <NavLink
          to={`/checkout?${
            isOnlyNotarization ? "isOnlyNotarization" : "isNeededNotarization"
          }`}
          style={{ width: 217 }}
          className="flex flex-center bg-primary-blue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md continue"
        >
          Continue to Checkout
        </NavLink>
      </div>
    </section>
  );
}

export default IdentityComplete;
