import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import User from "../../entities/User";
import FileUpload from "../Custom/FileUpload";
import { LoaderContext, ModalHowDigitalWorksContext } from "../../contexts";

function IdentityForm() {
  const { openImageSizeModal } = React.useContext(ModalHowDigitalWorksContext);
  const { startLoading, endLoading } = React.useContext(LoaderContext);

  const navigate = useNavigate();
  const [files, setFiles] = useState([]);

  const handleUploadFile = async (e) => {
    startLoading();
    e.preventDefault();

    if (!files?.filter((el) => !!el).length) {
      endLoading();

      return;
    }
    const resp = await User.saveDocument(files);
    if (resp.error) {
      endLoading();
      openImageSizeModal();
      return;
    }
    endLoading();
    navigate("/notarization/identity/complete");
  };

  const handleFileSet = (index) => (file) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles[index] = file;
      return newFiles;
    });
  };

  return (
    <form onSubmit={handleUploadFile} className="flex flex-center flex-col">
      <h3 className="font-semibold mb-6 text-4xl">Identity verification</h3>
      <p className="mb-8 w-[70%] text-center font-medium text-base text-dark-gray">
        In order to Notarize the Travel Consent we need to verify your identity.
        Upload a photo of your verification document. This can be your passport,
        ID card, driving license, or any other document that verifies your
        identity
      </p>
      <p style={{ marginBottom: 40 }}>
        Only the person/persons granting travel consent need to verify their
        identity
      </p>
      <div className="mb-6 parent-container" style={{ width: "100%" }}>
        {User.formData?.map((user, index) => (
          <div key={index} className="flex flex-col document-upload-block">
            <div
              className="count flex"
              style={{ marginRight: 40, marginBottom: 20 }}
            >
              <p
                className="font-semibold"
                style={{ color: "gray", fontSize: 18 }}
              >
                {index + 1}.
              </p>
              <p
                className="font-semibold"
                style={{ marginLeft: 30, fontSize: 18 }}
              >
                {user.name}
              </p>
            </div>
            <div
              className="input-doc-file"
              style={{ flexGrow: 1, marginLeft: 40 }}
            >
              <div className="flex flex-col space-y-4">
                <FileUpload set={handleFileSet(index)} />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-center">
        <NavLink
          to={"/notarization/choose"}
          className="back-form-button flex flex-center bg-primary-blue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md continue"
        >
          Back
        </NavLink>
        <button
          type="submit"
          className="flex flex-center bg-primary-blue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md continue"
        >
          Continue
        </button>
      </div>
    </form>
  );
}

export default IdentityForm;
