import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Form from "./components/ConsestForm/Form";
import Footer from "./components/Footer";
import NotarizationForm from "./components/NotarizationForm/Form";
import CheckoutForm from "./components/CheckoutForm";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { isFormPage, isMobile } from "./helpers";
import { ToastContainer } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import AboutUS from "./components/AboutUS";
import ContactForm from "./components/ContactForm";
import {
  ModalContext,
  ModalHowDigitalWorksContext,
  LoaderContext,
} from "./contexts";
import FAQPage from "./components/FAQPage";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import HowSignatureWorks from "./components/HowSignatureWorks";
import BlogPage from "./components/BlogPage";
import BlogItemPage from "./components/BlogItemPage";
import InformationPage from "./components/InformationPage";
import Spinner from "./components/Spinner";
const stripePromise = loadStripe(
  "pk_live_51MxnqEFb7TIrNtiqPxxnTSxeDA5rHeC5zj9eMqqn0IlkaLfyh7JzyT6r0L5uopoc6uq2lptYbXZvaoxiSkzABiUB00uo5gjn1W"
);

function App() {
  const location = useLocation();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [signatureModalIsOpen, setSignatureModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const value = {
    modalIsOpen,
    openModal: () => setModalIsOpen(true),
    closeModal: () => setModalIsOpen(false),
  };

  const valueSignature = {
    signatureModalIsOpen,
    openModalSignature: () => setSignatureModalIsOpen("signature"),
    openModalNotarize: () => setSignatureModalIsOpen("notarize"),
    openImageSizeModal: () => setSignatureModalIsOpen("imageSize"),
    openPreviewModal: () => setSignatureModalIsOpen("preview"),
    closeModal: () => setSignatureModalIsOpen(false),
  };

  const loadingValues = {
    isLoading,
    startLoading: () => setIsLoading(true),
    endLoading: () => setIsLoading(false),
  };

  useEffect(() => {
    if (!isFormPage()) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <ModalContext.Provider value={value}>
      <LoaderContext.Provider value={loadingValues}>
        <ModalHowDigitalWorksContext.Provider value={valueSignature}>
          <Spinner />
          <ContactForm />
          <HowSignatureWorks />
          <ToastContainer />
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/consent/*" element={<Form />} />
            <Route path="/about" element={<AboutUS />} />
            <Route path="/information/*" element={<InformationPage />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/blogs" element={<BlogPage />} />
            <Route path="/blog/*" element={<BlogItemPage />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/notarization/*" element={<NotarizationForm />} />
            <Route
              path="/checkout/*"
              element={
                <Elements stripe={stripePromise}>
                  <CheckoutForm />
                </Elements>
              }
            />
          </Routes>
          <Footer />
        </ModalHowDigitalWorksContext.Provider>
      </LoaderContext.Provider>
    </ModalContext.Provider>
  );
}

export default App;
