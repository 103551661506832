import React from "react";
import { NavLink } from "react-router-dom";
import { ModalHowDigitalWorksContext } from "../../contexts";

import "react-datepicker/dist/react-datepicker.css";

function PreviewForm({ parents }) {
  const { openPreviewModal } = React.useContext(ModalHowDigitalWorksContext);

  return (
    <form>
      <h3 className="font-semibold mb-6 text-2xl">Document Preview</h3>

      <p className="mb-8">
        We are pleased to inform you that your Travel Consent Document is now
        available for your review. To ensure the accuracy of the information you
        have provided, please click the preview button to see how your document
        will looks like.
        <br />
      </p>
      <div className="flex flex-center">
        <button
          type="button"
          className="flex flex-center bg-primary-blue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md continue"
          onClick={openPreviewModal}
        >
          Preview
        </button>
      </div>
    </form>
  );
}

export default PreviewForm;
