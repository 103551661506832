import React from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { ModalHowDigitalWorksContext } from "../contexts";
import HowItWorksPopup from "./HowItWorksPopup";
import NotarizationPopup from "./NotarizationPopup";
import ImageSizePopup from "./ImageSizePopup";
import PreviewPopup from "./PreviewPopup";

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 22222;
  background: rgba(0, 0, 0, 0.3);
`;

const ModalContainerSignature = styled(motion.div)`
  width: 100%;
  max-width: 998px;
  height: 50%;
  min-height: 466px;
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
`;

const modalVariant = {
  initial: { opacity: 0 },
  isOpen: { opacity: 1 },
  exit: { opacity: 0 },
};
const containerVariant = {
  initial: { top: "-50%", transition: { type: "spring" } },
  isOpen: { top: "50%" },
  exit: { top: "-50%" },
};

function HowSignatureWorks() {
  const { closeModal: handleClose, signatureModalIsOpen: openedModal } =
    React.useContext(ModalHowDigitalWorksContext);

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <AnimatePresence>
      {!!openedModal && (
        <Overlay
          initial={"initial"}
          animate={"isOpen"}
          exit={"exit"}
          variants={modalVariant}
          onClick={handleClose}
        >
          <ModalContainerSignature
            variants={containerVariant}
            onClick={stopPropagation}
            className="modal-signature max-lg:!h-[100vh] max-lg:!rounded-b-none max-lg:!overflow-auto"
          >
            <div className="close-button-hidden" onClick={handleClose}></div>
            {openedModal === "notarize" && (
              <HowItWorksPopup onClose={handleClose} />
            )}
            {openedModal === "signature" && (
              <NotarizationPopup onClose={handleClose} />
            )}
            {openedModal === "imageSize" && (
              <ImageSizePopup onClose={handleClose} />
            )}

            {openedModal === "preview" && (
              <PreviewPopup onClose={handleClose} />
            )}
          </ModalContainerSignature>
        </Overlay>
      )}
    </AnimatePresence>
  );
}

export default HowSignatureWorks;
