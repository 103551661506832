import React, { useState } from "react";
import User from "../../entities/User";
import FileUpload from "../Custom/FileUpload";
import { NavLink, useNavigate } from "react-router-dom";

function UserTravelConsent() {
  const navigate = useNavigate();
  const handleSubmitParent = (e) => {
    e.preventDefault();
    navigate("/consent/finish?isCustomTravelConsent");
  };

  return (
    <form onSubmit={handleSubmitParent}>
      <div className>
        <div className="flex flex-col items-center mb-6">
          <p
            className="text-3xl text-secondary-black font-bold"
            style={{ marginBottom: 18 }}
          >
            Upload the document
          </p>
        </div>

        <div>
          <div className="mb-6 flex flex-center" style={{ width: "100%" }}>
            <FileUpload
              set={(file) => {
                User.travelConsentFile = file;
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-center">
        <button
          type="submit"
          className="flex flex-center bg-primary-blue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md continue"
        >
          Continue
        </button>
      </div>
    </form>
  );
}

export default UserTravelConsent;
