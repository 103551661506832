import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Input from "../Input";

function AdultForm({ parents, handleSubmitParent }) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(parents);
  const [state, updateState] = useState(false);
  const data = formData[0];

  const handleChangeExpress = () => {
    setFormData({
      ...formData[0],
      [0]: {
        ...formData[0],
        adult_passport: null,
      },
    });
    updateState(!state);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...data,
      [0]: {
        ...data,
        [name]: value,
      },
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmitParent(formData);
    navigate("/consent/4");
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <h3 className="font-semibold mb-6 text-2xl">Accompanying Adult</h3>
      <div className="mb-6">
        <div className="flex form-container-usual">
          <div>
            <div className="flex flex-col space-y-4">
              <div className="flex inpt-12 gap-4">
                <Input
                  required
                  type="text"
                  name="adult_name"
                  value={data.adult_name}
                  placeholder="Type full name here"
                  label="Associated Adult Name"
                  onChange={handleInputChange}
                />
                <Input
                  required
                  type="text"
                  name="adult_relationship"
                  value={data.adult_relationship}
                  label="Relationship to the child"
                  onChange={handleInputChange}
                  placeholder="Indicate how this person is related to the child"
                />
              </div>

              {!state && (
                <Input
                  type="text"
                  name="adult_passport"
                  value={data.adult_passport}
                  placeholder="Please enter the accompanying adult's passport number"
                  onChange={handleInputChange}
                  label="Passport number (optional)*"
                />
              )}
              <div
                className="flex items-center express-del !m-0 !mt-4 max-lg:!flex-row max-lg:!justify-start max-lg:!items-start"
                style={{ marginLeft: 20 }}
              >
                <input
                  type="checkbox"
                  checked={state}
                  id="express"
                  className="mr-2"
                  style={{ width: 25, height: 25 }}
                  onChange={handleChangeExpress}
                />
                <label htmlFor="express" className="text-sm">
                  * If you do not have an Accompanying Person's passport number,
                  select this checkbox please.
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-center">
        <NavLink
          to={"/consent/2"}
          className="back-form-button flex flex-center bg-primary-blue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md continue"
        >
          Back
        </NavLink>
        <button className="flex flex-center bg-primary-blue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md continue">
          Continue
        </button>
      </div>
    </form>
  );
}

export default AdultForm;
