import React, { useState, useEffect, useRef, useMemo } from "react";
import { NavLink } from "react-router-dom";
import User from "../entities/User";
import { convertISOToDate } from "../helpers";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

function Home() {
  const [blockClasses, setBlockClasses] = useState([
    "default",
    "default",
    "default",
    "default",
    "default",
  ]);
  const [items, setItems] = useState([]);

  useEffect(() => {
    User.destroy();
  }, []);

  useEffect(() => {
    User.getAllBlogs()
      .then((res) => setItems(res.data))
      .catch(() => {});
  }, []);

  useEffect(() => {
    const allQuestions = document.getElementsByClassName("default");

    const questionsArray = Array.from(allQuestions);
    questionsArray.forEach((el) => {
      const buttonHeight = el.childNodes[0].offsetHeight;
      el.style.maxHeight = `${buttonHeight + 47}px`;
    });

    const item = document.getElementsByClassName("expanded");
    if (!item) {
      return;
    }
    const prepared =
      item[0]?.childNodes[1].offsetHeight +
      48 +
      item[0]?.childNodes[0].offsetHeight;
    if (item[0]?.style?.maxHeight) {
      item[0].style.maxHeight = `${prepared}px`;
    }
  }, [blockClasses]);

  const handleFaqExpand = (index) => {
    const newClasses = [...blockClasses];
    newClasses.map((_, idx) => (newClasses[idx] = "default"));
    if (blockClasses[index] !== "expanded") newClasses[index] = "expanded";
    setBlockClasses(newClasses);
  };

  return (
    <section>
      <div className="section home-top-section grid grid-cols-4">
        <div className="text-left text-white col-span-2 max-lg:col-span-4">
          <h1 className="text-7xl font-bold text-center-mobile">
            Travel Consent - done within minutes.
          </h1>
          <p className="text-xl my-8 text-center-mobile">
            Create, notarize, and download legally valid Travel Consent online.
          </p>
          <div className={"flex choose-section-12"}>
            <NavLink
              to={"/consent/1"}
              className="default-link bg-white text-secondary-black font-bold py-2 px-6 rounded mr-2 white-button-blue-transition"
            >
              Create travel consent
            </NavLink>
            <NavLink
              to={"/consent/travel/form"}
              className="default-link border border-white text-white font-bold py-2 px-6 rounded blue-button-white-transition"
            >
              Upload & Notarize
            </NavLink>
          </div>
        </div>
      </div>
      <div className="bg-gray-200 body-font section">
        <div className="info-container">
          <div className="text-center title">
            <h2 className="text-5xl font-medium text-secondary-black mb-4">
              Receive your travel consent fast & easy.
            </h2>
            <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-secondary-gray">
              Over 1000+ satisfied customers. Our experienced lawyers are here
              to help you.
            </p>
          </div>
          <div className="grid grid-cols-3 gap-5 max-md:grid-cols-2 max-sm:grid-cols-1">
            <div className="p-8 rounded-xl block max-xl:px-4">
              <img
                src="/images/contract.svg"
                className="mb-3 inline-block fas"
                alt="contract icon"
              />
              <h2 className="title-font font-semibold text-2xl text-secondary-black mb-2 max-lg:text-xl">
                Easy consent creation
              </h2>
              <p className="leading-relaxed font-medium text-dark-gray">
                Through our platform you can create your own travel consent form
                directly online within a few minutes.
              </p>
            </div>
            <div className="p-8 rounded-xl block max-xl:px-4">
              <img
                src="/images/check.svg"
                className="mb-3 inline-block fas"
                alt="check icon"
              />
              <h2 className="title-font font-semibold text-2xl text-secondary-black mb-2 max-lg:text-xl">
                Notarization by demand
              </h2>
              <p className="leading-relaxed font-medium text-dark-gray">
                Also need a notarization on your Travel Consent? We will arrange
                it for you.
              </p>
            </div>
            <div className="p-8 rounded-xl block max-xl:px-4">
              <img
                src="/images/upload.svg"
                className="mb-3 inline-block fas"
                alt="upload icon"
              />
              <h2 className="title-font font-semibold text-2xl text-secondary-black mb-2 max-lg:text-xl">
                Need Notarization?
              </h2>
              <p className="leading-relaxed font-medium text-dark-gray">
                Also need a notarization on your Travel Consent? We will arrange
                it for you.
              </p>
            </div>
            <div className="p-8 rounded-xl block max-xl:px-4">
              <img
                src="/images/user.svg"
                className="mb-3 inline-block fas"
                alt="user icon"
              />
              <h2 className="title-font font-semibold text-2xl text-secondary-black mb-2 max-lg:text-xl">
                Online identity verification
              </h2>
              <p className="leading-relaxed font-medium text-dark-gray">
                Verify your identity quick and easy through our platform in
                order to create a legally valid Travel Consent.
              </p>
            </div>
            <div className="p-8 rounded-xl block max-xl:px-4">
              <img
                src="/images/lock.svg"
                className="mb-3 inline-block fas"
                alt="lock icon"
              />
              <h2 className="title-font font-semibold text-2xl text-secondary-black mb-2 max-lg:text-xl">
                Internationally Valid
              </h2>
              <p className="leading-relaxed font-medium text-dark-gray">
                Our Travel Consents are internationally legal and we guarantee
                their validity.
              </p>
            </div>
            <div className="p-8 rounded-xl block max-xl:px-4">
              <img
                src="/images/users.svg"
                className="mb-3 inline-block fas"
                alt="users icon"
              />
              <h2 className="title-font font-semibold text-2xl text-secondary-black mb-2 max-lg:text-xl">
                100% user satisfaction
              </h2>
              <p className="leading-relaxed font-medium text-dark-gray">
                We always offer a full refund if, for some reason, you are not
                happy with our services.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="py-12 bg-white blog-block">
        <div className="">
          <div className="text-center mb-12">
            <h2 className="text-6xl font-bold text-secondary-black">
              Articles from our blog
            </h2>
          </div>
          <div className="block-section-wrapper">
            <div className="blog-section">
              <Swiper
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  480: {
                    slidesPerView: 1.5,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2.5,
                    spaceBetween: 30,
                  },
                  1024: {
                    slidesPerView: 3.5,
                    spaceBetween: 30,
                  },
                }}
                modules={[Pagination, Navigation]}
                navigation={{ prevEl: ".arrow-left", nextEl: ".arrow-right" }}
              >
                {items.map((blog, index) => (
                  <SwiperSlide
                    key={blog.id}
                    className="bg-primary-blue rounded-xl hover:bg-secondary-black"
                  >
                    <div className="blog-item">
                      <div className="p-6 flex flex-col">
                        <p>{convertISOToDate(blog.createdAt)}</p>
                        <h3 className="text-xl font-semibold text-white mb-4">
                          {blog.title}
                        </h3>
                        <p className="text-base text-white mb-4">
                          {blog.description}
                        </p>
                        <NavLink
                          to={`/blog/${blog.id}`}
                          style={{ marginTop: "auto" }}
                        >
                          Read more{" "}
                          <img
                            src="/images/chevron-right.svg"
                            className="inline-block"
                          />
                        </NavLink>
                      </div>
                      <img
                        className="object-cover rounded-2xl p-2 w-[100%] max-h-[220px]"
                        loading="lazy"
                        src={blog.image}
                        alt="blog image"
                      />
                    </div>
                  </SwiperSlide>
                ))}
                <button className="arrow-left arrow">
                  <img
                    src="/images/arrow-forward.svg"
                    alt="previous slide button"
                  />
                </button>
                <button className="arrow-right arrow">
                  <img
                    src="/images/arrow-forward.svg"
                    alt="next slide button"
                  />
                </button>
              </Swiper>
            </div>
          </div>
          <div className="text-center mt-8">
            <NavLink
              to={"/blogs"}
              className="inline-block text-secondary-black bg-transparent border border-solid border-black hover:bg-primary-blue hover:text-white active:bg-blue-600 font-bold px-8 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
            >
              Read our Blog
            </NavLink>
          </div>
        </div>
      </div>
      <div className="bg-gray-200 section faq-section">
        <div className="grid grid-cols-4 max-md:grid-cols-3 max-sm:grid-cols-none">
          <h2 className="text-4xl font-semibold text-secondary-black mb-4">
            FAQ
          </h2>
          <div className="space-y-4 right-section col-span-3 max-md:col-span-2">
            <div
              className={`bg-white faq-item ${blockClasses[0] || "default"}`}
            >
              <button
                className="flex items-center w-full text-left faq-button"
                onClick={() => handleFaqExpand(0)}
              >
                <i className="fas fa-plus"></i>
                <span className="font-medium text-secondary-black">
                  How does your service work?
                </span>
              </button>
              <div className="faq-add-information">
                <p className="pt-4 pb-2 text-gray-600 text-md">
                  Through our platform you can create your own travel consent
                  form directly online within a few minutes. You can download it
                  and use directly and it is guaranteed internationally valid.
                  You can also notarize your new or already existing travel
                  consent.
                </p>
              </div>
            </div>
            <div
              className={`bg-white faq-item ${blockClasses[1] || "default"}`}
            >
              <button
                className="flex items-center w-full text-left faq-button"
                onClick={() => handleFaqExpand(1)}
              >
                <i className="fas fa-plus"></i>
                <span className="font-medium text-secondary-black">
                  What is notarization?
                </span>
              </button>
              <div className="faq-add-information">
                <p className="pt-4 pb-2 text-gray-600 text-md">
                  Notarization is a process where a Notary Public—a certified
                  official—confirms the authenticity of a document or the
                  identity of the person signing it. This process helps prevent
                  fraud and confirms that the document is trustworthy and valid.
                </p>
              </div>
            </div>
            <div
              className={`bg-white faq-item ${blockClasses[2] || "default"}`}
            >
              <button
                className="flex items-center w-full text-left faq-button"
                onClick={() => handleFaqExpand(2)}
              >
                <i className="fas fa-plus"></i>
                <span className="font-medium text-secondary-black">
                  Is the Travel Consent Internationally Valid?
                </span>
              </button>
              <div className="faq-add-information">
                <p className="pt-4 pb-2 text-gray-600 text-md">
                  Yes, our travel consents are created by International Lawyers
                  and we guarantee the legal validity of the consent.
                </p>
              </div>
            </div>
            <div
              className={`bg-white faq-item ${blockClasses[3] || "default"}`}
            >
              <button
                className="flex items-center w-full text-left faq-button"
                onClick={() => handleFaqExpand(3)}
              >
                <i className="fas fa-plus"></i>
                <span className="font-medium text-secondary-black">
                  What is a Travel Consent form?
                </span>
              </button>
              <div className="faq-add-information">
                <p className="pt-4 pb-2 text-gray-600 text-md">
                  A Travel Consent form is a document that gives permission for
                  a minor to travel with an adult who is not their legal
                  guardian or if the minor is only travelling with one guardian.
                </p>
              </div>
            </div>
            <div
              className={`bg-white faq-item ${blockClasses[4] || "default"}`}
            >
              <button
                className="flex items-center w-full text-left faq-button"
                onClick={() => handleFaqExpand(4)}
              >
                <i className="fas fa-plus"></i>
                <span className="font-medium text-secondary-black">
                  Why do I need a Travel Consent form notarized?
                </span>
              </button>
              <div className="faq-add-information">
                <p className="pt-4 pb-2 text-gray-600 text-md">
                  Notarizing a Travel Consent form helps verify the authenticity
                  of the document and the identity of the signatories, ensuring
                  the travel arrangements are legally recognized and reducing
                  the risk of child abduction etc. This is why some authorities
                  always request notarized Travel Consents.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mt-8 more-button">
          <NavLink
            to={"/faq"}
            className="inline-block font-medium text-secondary-black bg-transparent border-[1.5px] border-solid border-gray-66 hover:bg-primary-blue hover:text-white hover:border-blue-600 active:bg-blue-600 px-6 py-4 rounded-xl outline-none focus:outline-none ease-linear transition-all duration-150"
            type="button"
          >
            More on FAQ page
          </NavLink>
        </div>
      </div>
    </section>
  );
}

export default Home;
