import React, { useEffect, useState } from "react";
import { ModalContext } from "../contexts";
import User from "../entities/User";

function FAQPage() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isAbsolute, setIsAbsolute] = useState(null);

  const [blockClasses, setBlockClasses] = useState([
    "default",
    "default",
    "default",
    "default",
    "default",
    "default",
    "default",
    "default",
    "default",
    "default",
    "default",
    "default",
    "default",
  ]);
  const { openModal } = React.useContext(ModalContext);
  const hash = window.location.hash;

  const scrollListener = () => {
    window.scrollY > document.getElementById("left-side").offsetTop - 20
      ? setIsScrolled(true)
      : setIsScrolled(false);

    window.scrollY > document.getElementById("section4").offsetTop + 120
      ? setIsAbsolute(true)
      : setIsAbsolute(null);
  };
  window.addEventListener("scroll", scrollListener);

  useEffect(() => {
    User.destroy();
    return () => {
      window.removeEventListener("scroll", scrollListener);
    };
  }, []);

  useEffect(() => {
    const allQuestions = document.getElementsByClassName("default");

    const questionsArray = Array.from(allQuestions);
    questionsArray.forEach((el) => {
      const buttonHeight = el.childNodes[0].offsetHeight;
      el.style.maxHeight = `${buttonHeight + 47}px`;
    });

    const item = document.getElementsByClassName("expanded");
    if (!item) {
      return;
    }
    const prepared =
      item[0]?.childNodes[1].offsetHeight +
      48 +
      item[0]?.childNodes[0].offsetHeight;
    if (item[0]?.style?.maxHeight) {
      item[0].style.maxHeight = `${prepared}px`;
    }
  }, []);

  useEffect(() => {
    const allQuestions = document.getElementsByClassName("default");

    const questionsArray = Array.from(allQuestions);
    questionsArray.forEach((el) => {
      const buttonHeight = el.childNodes[0].offsetHeight;
      el.style.maxHeight = `${buttonHeight + 47}px`;
    });

    const item = document.getElementsByClassName("expanded");
    if (!item) {
      return;
    }
    const prepared =
      item[0]?.childNodes[1].offsetHeight +
      48 +
      item[0]?.childNodes[0].offsetHeight;
    if (item[0]?.style?.maxHeight) {
      item[0].style.maxHeight = `${prepared}px`;
    }
  }, [blockClasses]);

  const handleFaqExpand = (index) => {
    const newClasses = [...blockClasses];
    newClasses.map((_, idx) => (newClasses[idx] = "default"));
    if (blockClasses[index] !== "expanded") newClasses[index] = "expanded";
    setBlockClasses(newClasses);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <section className="faq-page">
      <div className="title-faq">
        <h1 className="font-semibold">Frequently Asked Questions</h1>
        <p>
          If you were unable to find the answer to your questions, please reach
          out to us <span onClick={openModal}>here</span>
        </p>
      </div>
      <div className="info-page">
        <div className="left-side" id="left-side">
          <div
            className={`anchor-block ${isScrolled ? "fixed" : ""} ${
              isAbsolute ? "absolute-123" : ""
            }`}
          >
            <h3 className="font-semibold">ON THIS PAGE</h3>
            <a
              href="#section1"
              className={hash === "#section1" ? "active" : ""}
            >
              General questions
            </a>
            <a
              href="#section2"
              className={hash === "#section2" ? "active" : ""}
            >
              About travel consents & notarization
            </a>
            <a
              href="#section3"
              className={hash === "#section3" ? "active" : ""}
            >
              Payment & Refunds
            </a>
            <a
              href="#section4"
              className={hash === "#section4" ? "active" : ""}
            >
              Other questions
            </a>
          </div>
          <a className="scroll-to-top-button" onClick={scrollToTop}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_1564_2436"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_1564_2436)">
                <path
                  d="M11 11.8V15C11 15.2833 11.0958 15.5208 11.2875 15.7125C11.4792 15.9042 11.7167 16 12 16C12.2833 16 12.5208 15.9042 12.7125 15.7125C12.9042 15.5208 13 15.2833 13 15V11.8L13.9 12.7C14.0833 12.8833 14.3167 12.975 14.6 12.975C14.8833 12.975 15.1167 12.8833 15.3 12.7C15.4833 12.5167 15.575 12.2833 15.575 12C15.575 11.7167 15.4833 11.4833 15.3 11.3L12.7 8.7C12.5 8.5 12.2667 8.4 12 8.4C11.7333 8.4 11.5 8.5 11.3 8.7L8.7 11.3C8.51667 11.4833 8.425 11.7167 8.425 12C8.425 12.2833 8.51667 12.5167 8.7 12.7C8.88333 12.8833 9.11667 12.975 9.4 12.975C9.68333 12.975 9.91667 12.8833 10.1 12.7L11 11.8ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                  fill="#9C9C9C"
                />
              </g>
            </svg>
            Back to top
          </a>
        </div>
        <div className="right-side">
          <div>
            <h2 className="font-semibold" id="section1">
              General questions
            </h2>
            <div className="section faq-section">
              <div className="faq-questions">
                <div
                  className={`border-b faq-item ${
                    blockClasses[0] || "default"
                  }`}
                >
                  <button
                    className="flex items-center w-full text-left faq-button"
                    onClick={() => handleFaqExpand(0)}
                  >
                    <i className="fas fa-plus"></i>
                    <span className="font-medium text-secondary-black">
                      What is a Travel Consent form?
                    </span>
                  </button>
                  <div className="faq-add-information">
                    <p className="pt-4 pb-2 text-gray-600 text-md">
                      A Travel Consent form is a document that allows a minor
                      child to travel with someone other than their parent or
                      legal guardian. This form provides legal authorization and
                      peace of mind, ensuring that the accompanying adult has
                      permission to travel with the child.
                    </p>
                  </div>
                </div>
                <div
                  className={`border-b faq-item ${
                    blockClasses[1] || "default"
                  }`}
                >
                  <button
                    className="flex items-center w-full text-left faq-button"
                    onClick={() => handleFaqExpand(1)}
                  >
                    <i className="fas fa-plus"></i>
                    <span className="font-medium text-secondary-black">
                      When do I need a Travel Consent form?
                    </span>
                  </button>
                  <div className="faq-add-information">
                    <p className="pt-4 pb-2 text-gray-600 text-md">
                      You need a Travel Consent form when your child is
                      traveling alone, with one parent, or with another adult
                      who is not their legal guardian. This is especially
                      important for international travel, as border officials
                      may request proof of consent.
                    </p>
                  </div>
                </div>
                <div
                  className={`border-b faq-item ${
                    blockClasses[2] || "default"
                  }`}
                >
                  <button
                    className="flex items-center w-full text-left faq-button"
                    onClick={() => handleFaqExpand(2)}
                  >
                    <i className="fas fa-plus"></i>
                    <span className="font-medium text-secondary-black">
                      What information is included in a Travel Consent form?
                    </span>
                  </button>
                  <div className="faq-add-information">
                    <h4 className="pt-4 text-gray-600 text-xl mb-2">
                      A typical Travel Consent form includes:
                    </h4>
                    <ul className="text-gray-600">
                      <li> • Child’s full name and date of birth</li>
                      <li>
                        • Name and contact information of parents or legal
                        guardians
                      </li>
                      <li>• Travel dates and destinations</li>
                      <li>
                        • Information about the accompanying adult (if
                        applicable)
                      </li>
                      <li>• Signatures of parents or legal guardians</li>
                      <li>• Notarization or apostille, if required</li>
                    </ul>
                  </div>
                </div>
                <div
                  className={`border-b faq-item ${
                    blockClasses[3] || "default"
                  }`}
                >
                  <button
                    className="flex items-center w-full text-left faq-button"
                    onClick={() => handleFaqExpand(3)}
                  >
                    <i className="fas fa-plus"></i>
                    <span className="font-medium text-secondary-black">
                      How does your service work?
                    </span>
                  </button>
                  <div className="faq-add-information">
                    <h4 className="pt-4 text-gray-600 text-xl mb-2">
                      A typical Travel Consent form includes:
                    </h4>
                    <ul className="text-gray-600">
                      <li>
                        • Through our platform you can create your own travel
                        consent form directly online within a few minutes. You
                        can download it and use directly and it is guaranteed
                        internationally valid. You can also notarize your new or
                        already existing travel consent.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h3 className="font-semibold" id="section2">
              About travel consents & notarization
            </h3>
            <div className="section faq-section">
              <div className="faq-questions">
                <div
                  className={`border-b faq-item ${
                    blockClasses[5] || "default"
                  }`}
                >
                  <button
                    className="flex items-center w-full text-left faq-button"
                    onClick={() => handleFaqExpand(5)}
                  >
                    <i className="fas fa-plus"></i>
                    <span className="font-medium text-secondary-black">
                      Is notarization necessary for a Travel Consent form?
                    </span>
                  </button>
                  <div className="faq-add-information">
                    <p className="pt-4 pb-2 text-gray-600 text-md">
                      Yes, in many cases, notarization is required to
                      authenticate the signatures on the Travel Consent form.
                      This adds an extra layer of verification to ensure the
                      document is legally recognized.
                    </p>
                  </div>
                </div>
                <div
                  className={`border-b faq-item ${
                    blockClasses[6] || "default"
                  }`}
                >
                  <button
                    className="flex items-center w-full text-left faq-button"
                    onClick={() => handleFaqExpand(6)}
                  >
                    <i className="fas fa-plus"></i>
                    <span className="font-medium text-secondary-black">
                      How do I get my Travel Consent form notarized?
                    </span>
                  </button>
                  <div className="faq-add-information">
                    <p className="pt-4 pb-2 text-gray-600 text-md">
                      You can get your Travel Consent form notarized on our
                      website from a Notary Public. One or both parents or legal
                      guardians should sign the document and the Notary Public
                      verify their identities and apply a notarial seal. We can
                      arrange the notarization if you create a travel consent
                      with us or if you already have one.
                    </p>
                  </div>
                </div>
                <div
                  className={`border-b faq-item ${
                    blockClasses[7] || "default"
                  }`}
                >
                  <button
                    className="flex items-center w-full text-left faq-button"
                    onClick={() => handleFaqExpand(7)}
                  >
                    <i className="fas fa-plus"></i>
                    <span className="font-medium text-secondary-black">
                      How long is a Travel Consent form valid?
                    </span>
                  </button>
                  <div className="faq-add-information">
                    <p className="pt-4 pb-2 text-gray-600 text-md">
                      The validity of a Travel Consent form depends on the
                      travel dates specified in the document. It should cover
                      the entire duration of the trip. For repeated travels, a
                      new consent form should be prepared for each trip.
                    </p>
                  </div>
                </div>

                <div
                  className={`border-b faq-item ${
                    blockClasses[8] || "default"
                  }`}
                >
                  <button
                    className="flex items-center w-full text-left faq-button"
                    onClick={() => handleFaqExpand(8)}
                  >
                    <i className="fas fa-plus"></i>
                    <span className="font-medium text-secondary-black">
                      Are there any additional documents I should carry along
                      with the Travel Consent form?
                    </span>
                  </button>
                  <div className="faq-add-information">
                    <p className="pt-4 pb-2 text-gray-600 text-md">
                      Yes, it's advisable to carry a copy of the child's birth
                      certificate, a copy of the parents' or guardians'
                      identification, and any relevant custody documents. These
                      additional documents provide further proof of parental
                      consent and identity.
                    </p>
                  </div>
                </div>

                <div
                  className={`border-b faq-item ${
                    blockClasses[9] || "default"
                  }`}
                >
                  <button
                    className="flex items-center w-full text-left faq-button"
                    onClick={() => handleFaqExpand(9)}
                  >
                    <i className="fas fa-plus"></i>
                    <span className="font-medium text-secondary-black">
                      How can I ensure my Travel Consent form is accepted
                      internationally?
                    </span>
                  </button>
                  <div className="faq-add-information">
                    <p className="pt-4 pb-2 text-gray-600 text-md">
                      To ensure international acceptance, the Travel Consent
                      form should be notarized, and an apostille may be
                      required. This certification validates the notarization
                      for use in foreign countries. Check the specific
                      requirements of the destination country.
                    </p>
                  </div>
                </div>

                <div
                  className={`border-b faq-item ${
                    blockClasses[10] || "default"
                  }`}
                >
                  <button
                    className="flex items-center w-full text-left faq-button"
                    onClick={() => handleFaqExpand(10)}
                  >
                    <i className="fas fa-plus"></i>
                    <span className="font-medium text-secondary-black">
                      Can travelconsent24.com assist with document translation?
                    </span>
                  </button>
                  <div className="faq-add-information">
                    <p className="pt-4 pb-2 text-gray-600 text-md">
                      Yes, if your document needs to be translated into another
                      language, we offer certified translation services to
                      ensure it meets the requirements of the destination
                      country.
                    </p>
                  </div>
                </div>

                <div
                  className={`border-b faq-item ${
                    blockClasses[11] || "default"
                  }`}
                >
                  <button
                    className="flex items-center w-full text-left faq-button"
                    onClick={() => handleFaqExpand(11)}
                  >
                    <i className="fas fa-plus"></i>
                    <span className="font-medium text-secondary-black">
                      How do I contact travelconsent24.com for more help?
                    </span>
                  </button>
                  <div className="faq-add-information">
                    <p className="pt-4 pb-2 text-gray-600 text-md">
                      You can contact us through our website by filling out the
                      contact form or sending us an email at
                      support@travelconsent24.com. We are here to help with any
                      questions or concerns.
                      <br />
                      <br />
                      Feel free to reach out to us if you have any other
                      questions or need further assistance with your Travel
                      Consent form. Safe travels!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h2 className="font-semibold" id="section3">
              Payment & Refunds
            </h2>
            <div className="section faq-section">
              <div className="faq-questions">
                <div
                  className={`border-b faq-item ${
                    blockClasses[12] || "default"
                  }`}
                >
                  <button
                    className="flex items-center w-full text-left faq-button"
                    onClick={() => handleFaqExpand(12)}
                  >
                    <i className="fas fa-plus"></i>
                    <span className="font-medium text-secondary-black">
                      What payment methods do you accept?
                    </span>
                  </button>
                  <div className="faq-add-information">
                    <p className="pt-4 pb-2 text-gray-600 text-md">
                      We accept various payment methods for your convenience,
                      including credit cards, debit cards, PayPal, and bank
                      transfers. All payments are processed securely to protect
                      your financial information.
                    </p>
                  </div>
                </div>

                <div
                  className={`border-b faq-item ${
                    blockClasses[13] || "default"
                  }`}
                >
                  <button
                    className="flex items-center w-full text-left faq-button"
                    onClick={() => handleFaqExpand(13)}
                  >
                    <i className="fas fa-plus"></i>
                    <span className="font-medium text-secondary-black">
                      Is it safe to make a payment on your website?
                    </span>
                  </button>
                  <div className="faq-add-information">
                    <p className="pt-4 pb-2 text-gray-600 text-md">
                      Yes, it is completely safe to make a payment on our
                      website. We use advanced encryption technology and secure
                      payment gateways to ensure that your financial information
                      is protected.
                    </p>
                  </div>
                </div>

                <div
                  className={`border-b faq-item ${
                    blockClasses[14] || "default"
                  }`}
                >
                  <button
                    className="flex items-center w-full text-left faq-button"
                    onClick={() => handleFaqExpand(14)}
                  >
                    <i className="fas fa-plus"></i>
                    <span className="font-medium text-secondary-black">
                      Can I get an invoice for my purchase?
                    </span>
                  </button>
                  <div className="faq-add-information">
                    <p className="pt-4 pb-2 text-gray-600 text-md">
                      Yes, you will receive an invoice via email after your
                      payment is processed. If you need a physical copy or have
                      specific invoicing requirements, please contact our
                      customer service team.
                    </p>
                  </div>
                </div>

                <div
                  className={`border-b faq-item ${
                    blockClasses[15] || "default"
                  }`}
                >
                  <button
                    className="flex items-center w-full text-left faq-button"
                    onClick={() => handleFaqExpand(15)}
                  >
                    <i className="fas fa-plus"></i>
                    <span className="font-medium text-secondary-black">
                      Do you offer refunds?
                    </span>
                  </button>
                  <div className="faq-add-information">
                    <p className="pt-4 pb-2 text-gray-600 text-md">
                      Yes, we offer a money-back guarantee if you are not
                      satisfied with our service. If you encounter any issues or
                      are dissatisfied with your purchase, please contact our
                      customer service team within 30 days of your purchase to
                      request a refund.
                    </p>
                  </div>
                </div>

                <div
                  className={`border-b faq-item ${
                    blockClasses[16] || "default"
                  }`}
                >
                  <button
                    className="flex items-center w-full text-left faq-button"
                    onClick={() => handleFaqExpand(16)}
                  >
                    <i className="fas fa-plus"></i>
                    <span className="font-medium text-secondary-black">
                      What is your refund policy?
                    </span>
                  </button>
                  <div className="faq-add-information">
                    <p className="pt-4 pb-2 text-gray-600 text-md">
                      Our refund policy is straightforward: if you are not
                      satisfied with our services, you can request a refund
                      within 30 days of your purchase. We will process your
                      refund promptly, usually within 5-7 business days.
                    </p>
                  </div>
                </div>

                <div
                  className={`border-b faq-item ${
                    blockClasses[17] || "default"
                  }`}
                >
                  <button
                    className="flex items-center w-full text-left faq-button"
                    onClick={() => handleFaqExpand(17)}
                  >
                    <i className="fas fa-plus"></i>
                    <span className="font-medium text-secondary-black">
                      Do you store my payment information?
                    </span>
                  </button>
                  <div className="faq-add-information">
                    <p className="pt-4 pb-2 text-gray-600 text-md">
                      No, we do not store your payment information. All payment
                      details are processed securely through our payment
                      gateway, and we do not have access to your financial
                      information.
                    </p>
                  </div>
                </div>

                <div
                  className={`border-b faq-item ${
                    blockClasses[18] || "default"
                  }`}
                >
                  <button
                    className="flex items-center w-full text-left faq-button"
                    onClick={() => handleFaqExpand(18)}
                  >
                    <i className="fas fa-plus"></i>
                    <span className="font-medium text-secondary-black">
                      Who can I contact for payment and refund-related
                      questions?
                    </span>
                  </button>
                  <div className="faq-add-information">
                    <p className="pt-4 pb-2 text-gray-600 text-md">
                      For any payment and refund-related questions, please
                      contact our customer service team via email at
                      support@travelconsent24.com. We are here to assist you
                      with any concerns or issues you may have.
                      <br />
                      <br />
                      Feel free to reach out to us if you have any other
                      questions or need further assistance with payments and
                      refunds. We are committed to ensuring a smooth and secure
                      transaction experience for all our customers.
                    </p>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
          <div>
            <h3 className="font-semibold" id="section4">
              Other Questions
            </h3>
            <div className="section faq-section">
              <div className="faq-questions">
                <div
                  className={`border-b faq-item ${
                    blockClasses[19] || "default"
                  }`}
                >
                  <button
                    className="flex items-center w-full text-left faq-button"
                    onClick={() => handleFaqExpand(19)}
                  >
                    <i className="fas fa-plus"></i>
                    <span className="font-medium text-secondary-black">
                      Do you offer any other services besides Travel Consent
                      forms?
                    </span>
                  </button>
                  <div className="faq-add-information">
                    <p className="pt-4 pb-2 text-gray-600 text-md">
                      Yes, we offer a variety of services including apostille
                      certification, notarization, document translation, and
                      document retrieval. Visit our website or contact us for
                      more information on all the services we provide.
                    </p>
                  </div>
                </div>

                <div
                  className={`border-b faq-item ${
                    blockClasses[20] || "default"
                  }`}
                >
                  <button
                    className="flex items-center w-full text-left faq-button"
                    onClick={() => handleFaqExpand(20)}
                  >
                    <i className="fas fa-plus"></i>
                    <span className="font-medium text-secondary-black">
                      How do I know if I need an apostille or just notarization?
                    </span>
                  </button>
                  <div className="faq-add-information">
                    <p className="pt-4 pb-2 text-gray-600 text-md">
                      An apostille might be required for documents that will be
                      used in foreign countries that are members of the Hague
                      Apostille Convention. Notarization is sufficient for
                      domestic documents or for countries that do not require an
                      apostille. If you are unsure, please contact us for
                      guidance.
                    </p>
                  </div>
                </div>

                <div
                  className={`border-b faq-item ${
                    blockClasses[21] || "default"
                  }`}
                >
                  <button
                    className="flex items-center w-full text-left faq-button"
                    onClick={() => handleFaqExpand(21)}
                  >
                    <i className="fas fa-plus"></i>
                    <span className="font-medium text-secondary-black">
                      How can I check the status of my order?
                    </span>
                  </button>
                  <div className="faq-add-information">
                    <p className="pt-4 pb-2 text-gray-600 text-md">
                      You can check the status of your order by contacting our
                      customer service team via email or phone. Please provide
                      your order number and we will update you on the progress.
                    </p>
                  </div>
                </div>

                <div
                  className={`border-b faq-item ${
                    blockClasses[22] || "default"
                  }`}
                >
                  <button
                    className="flex items-center w-full text-left faq-button"
                    onClick={() => handleFaqExpand(22)}
                  >
                    <i className="fas fa-plus"></i>
                    <span className="font-medium text-secondary-black">
                      What should I do if I need to make changes to my Travel
                      Consent form after it has been completed?
                    </span>
                  </button>
                  <div className="faq-add-information">
                    <p className="pt-4 pb-2 text-gray-600 text-md">
                      If you need to make changes to your Travel Consent form
                      after it has been completed, please contact our customer
                      service team as soon as possible. We will assist you in
                      making the necessary amendments.
                    </p>
                  </div>
                </div>

                <div
                  className={`border-b faq-item ${
                    blockClasses[23] || "default"
                  }`}
                >
                  <button
                    className="flex items-center w-full text-left faq-button"
                    onClick={() => handleFaqExpand(23)}
                  >
                    <i className="fas fa-plus"></i>
                    <span className="font-medium text-secondary-black">
                      Can I get my documents translated into multiple languages?
                    </span>
                  </button>
                  <div className="faq-add-information">
                    <p className="pt-4 pb-2 text-gray-600 text-md">
                      Yes, we offer certified translation services for multiple
                      languages. Please contact us with your specific
                      requirements, and we will provide you with a quote and
                      timeline for the translations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-info">
            Haven't found the answer to your question?{" "}
            <span onClick={openModal}>Contact us</span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQPage;
