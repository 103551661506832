const NotarizationPopup = ({ onClose }) => {
  return (
    <div className="bg-white top-[50%] translate-y-[-50%] p-8 rounded-xl max-w-[996px] absolute w-[100%] translate-x-[-50%] left-[50%] max-lg:bottom-0 max-lg:!rounded-b-none max-lg:!h-[100vh] max-lg:!overflow-auto text-secondary-black">
      <h3 className="text-32 font-semibold mb-4">
        Sign digitally now or manually later?
      </h3>
      <img
        src="/images/cross.svg"
        alt="close modal icon"
        className="absolute right-[8px] top-[8px]"
        onClick={onClose}
      />
      <p className="mb-6 text-base text-dark-gray">
        For more detailed information, please refer to{" "}
        <a className="text-primary-blue" href="/information/14">
          this article.
        </a>
      </p>
      <div className="flex justify-around gap-4 max-lg:flex-col">
        <div className="item-block bg-light-gray  rounded-xl flex flex-col justify-between">
          <img
            src="/images/sign_image.png"
            alt="sign image"
            className="w-[100%] h-[auto] p-4"
          />
          <div className="rounded-xl flex flex-col gap-2 bg-white m-2 p-2">
            <h3 className="text-base font-semibold">Sign digitally now</h3>
            <p className="text-dark-gray text-sm">
              You can either use autogenerated digital signature, or draw one
              yourself using your mouse or touchpad. This type of signature is
              100% legal and will work everywhere.
            </p>
          </div>
        </div>
        <div className="item-block bg-light-gray  rounded-xl flex flex-col justify-between">
          <img
            src="/images/sign_image_2.png"
            alt="sign image2"
            className="w-[100%] h-[auto] p-0"
          />
          <div className="rounded-xl flex flex-col gap-2 bg-white m-2 p-2">
            <h3 className="text-base font-semibold">Sign manually later</h3>
            <p className="text-dark-gray text-sm">
              You can skip the signature process for now and print this document
              later on in order to put your manual signature with your own hands
              using a pan.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotarizationPopup;
