import { NavLink, useNavigate } from "react-router-dom";
import User from "../entities/User";

const PreviewPopup = ({ onClose }) => {
  const formData = User.formData[0];
  const additionalData = User.formData[1];
  const navigate = useNavigate();

  const onProceed = () => {
    navigate("/consent/finish");
    onClose();
  };

  function formatDate(dateString) {
    if (!dateString) {
      return;
    }
    const [year, month, day] = dateString.split("-");

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthName = monthNames[parseInt(month) - 1];

    return `${day} ${monthName} ${year}`;
  }

  return (
    <div className="bg-white top-[50%] translate-y-[-50%] p-8 rounded-xl max-w-[996px] absolute w-[100%] translate-x-[-50%] left-[50%] max-lg:bottom-0 max-lg:!rounded-b-none max-lg:!h-[100vh] max-lg:!overflow-auto flex flex-col max-h-[100vh] overflow-auto">
      <h3 className="text-32 font-semibold mb-4 text-center">
        Child Travel Consent Form.
      </h3>
      <img
        src="/images/cross.svg"
        alt="close modal icon"
        className="absolute right-[8px] top-[8px]"
        onClick={onClose}
      />

      <div className="p-4 flex flex-col gap-8">
        <section className="relative">
          <h2 className="text-lg font-semibold text-secondary-black">
            Introduction
          </h2>
          <p className="text-base text-dark-gray">
            This document serves to provide consent from the undersigned legal
            guardian(s) for the named child to travel with the specifed adult.
          </p>
        </section>
        <section className="relative">
          <div className="watermark">
            This watermark text will be removed from the final document
          </div>
          <h2 className="text-lg font-semibold text-secondary-black">
            Child Details
          </h2>

          <div className="grid grid-cols-2 max-w-[400px] mx-auto">
            <p className="text-base text-dark-gray">Name:</p>
            <p className="text-base text-dark-gray te">{formData.minor_name}</p>
          </div>
          <div className="grid grid-cols-2 max-w-[400px] mx-auto">
            <p className="text-base text-dark-gray">Date of Birth:</p>
            <p className="text-base text-dark-gray">
              {formatDate(formData.minor_date_of_birth)}
            </p>
          </div>
          <div className="grid grid-cols-2 max-w-[400px] mx-auto">
            <p className="text-base text-dark-gray">Passport Number:</p>
            <p className="text-base text-dark-gray">
              {formData.minor_passport}
            </p>
          </div>
        </section>

        <section className="relative">
          <div className="watermark">
            This watermark text will be removed from the final document
          </div>
          <h2 className="text-lg font-semibold text-secondary-black">
            Guardian Details
          </h2>

          <div className="grid grid-cols-2 max-w-[400px] mx-auto">
            <p className="text-base text-dark-gray">Name:</p>
            <p className="text-base text-dark-gray te">{formData.name}</p>
          </div>
          <div className="grid grid-cols-2 max-w-[400px] mx-auto">
            <p className="text-base text-dark-gray">Date of Birth:</p>
            <p className="text-base text-dark-gray">
              {formatDate(formData.parent_birthday)}
            </p>
          </div>
          <div className="grid grid-cols-2 max-w-[400px] mx-auto">
            <p className="text-base text-dark-gray">Passport Number:</p>
            <p className="text-base text-dark-gray">
              {formData.parent_passport}
            </p>
          </div>
          <div className="grid grid-cols-2 max-w-[400px] mx-auto">
            <p className="text-base text-dark-gray">Relationship to Child:</p>
            <p className="text-base text-dark-gray">{formData.relationship}</p>
          </div>
          <div className="grid grid-cols-2 max-w-[400px] mx-auto">
            <p className="text-base text-dark-gray">Phone Number:</p>
            <p className="text-base text-dark-gray">{formData.phone}</p>
          </div>
          <div className="grid grid-cols-2 max-w-[400px] mx-auto">
            <p className="text-base text-dark-gray">Address:</p>
            <p className="text-base text-dark-gray">{formData.address}</p>
          </div>
        </section>

        {additionalData && (
          <section className="relative">
            <div className="watermark">
              This watermark text will be removed from the final document
            </div>
            <h2 className="text-lg font-semibold text-secondary-black">
              Second Guardian Details
            </h2>

            <div className="grid grid-cols-2 max-w-[400px] mx-auto">
              <p className="text-base text-dark-gray">Name:</p>
              <p className="text-base text-dark-gray te">
                {additionalData.name}
              </p>
            </div>
            <div className="grid grid-cols-2 max-w-[400px] mx-auto">
              <p className="text-base text-dark-gray">Date of Birth:</p>
              <p className="text-base text-dark-gray">
                {formatDate(additionalData.parent_birthday)}
              </p>
            </div>
            <div className="grid grid-cols-2 max-w-[400px] mx-auto">
              <p className="text-base text-dark-gray">Passport Number:</p>
              <p className="text-base text-dark-gray">
                {additionalData.parent_passport}
              </p>
            </div>
            <div className="grid grid-cols-2 max-w-[400px] mx-auto">
              <p className="text-base text-dark-gray">Relationship to Child:</p>
              <p className="text-base text-dark-gray">
                {additionalData.relationship}
              </p>
            </div>
            <div className="grid grid-cols-2 max-w-[400px] mx-auto">
              <p className="text-base text-dark-gray">Phone Number:</p>
              <p className="text-base text-dark-gray">{additionalData.phone}</p>
            </div>
            <div className="grid grid-cols-2 max-w-[400px] mx-auto">
              <p className="text-base text-dark-gray">Address:</p>
              <p className="text-base text-dark-gray">{formData.address}</p>
            </div>
          </section>
        )}

        <section className="relative">
          <div className="watermark">
            This watermark text will be removed from the final document
          </div>
          <h2 className="text-lg font-semibold text-secondary-black">
            Accompanying Adult Details
          </h2>

          <div className="grid grid-cols-2 max-w-[400px] mx-auto">
            <p className="text-base text-dark-gray">Name:</p>
            <p className="text-base text-dark-gray te">{formData.adult_name}</p>
          </div>

          {formData.adult_passport && (
            <div className="grid grid-cols-2 max-w-[400px] mx-auto">
              <p className="text-base text-dark-gray">Passport Number:</p>
              <p className="text-base text-dark-gray">
                {formData.adult_passport}
              </p>
            </div>
          )}
          <div className="grid grid-cols-2 max-w-[400px] mx-auto">
            <p className="text-base text-dark-gray">Relationship to Child:</p>
            <p className="text-base text-dark-gray">
              {formData.adult_relationship}
            </p>
          </div>
        </section>

        <section className="relative">
          <div className="watermark">
            This watermark text will be removed from the final document
          </div>
          <h2 className="text-lg font-semibold text-secondary-black">
            Travel Details
          </h2>

          <div className="grid grid-cols-2 max-w-[400px] mx-auto">
            <p className="text-base text-dark-gray">Destination:</p>
            <p className="text-base text-dark-gray te">
              {formData.destination}
            </p>
          </div>
          <div className="grid grid-cols-2 max-w-[400px] mx-auto">
            <p className="text-base text-dark-gray">Start of the trip:</p>
            <p className="text-base text-dark-gray">
              {formatDate(formData.date_trip_start)}
            </p>
          </div>
          {/* {!!formData.date_trip_end && (
            <div className="grid grid-cols-2 max-w-[400px] mx-auto">
              <p className="text-base text-dark-gray">End of the trip:</p>
              <p className="text-base text-dark-gray">
                {formatDate(formData.date_trip_end)}
              </p>
            </div>
          )} */}

          {!!formData.special_instructions && (
            <div className="max-w-[400px] mx-auto">
              <p className="text-base text-dark-gray inline-block w-[200px]">
                Special instructions:
              </p>
              <p className="text-base text-dark-gray inline">
                {formData.special_instructions}
              </p>
            </div>
          )}
        </section>
        <section className="relative">
          <h2 className="text-lg font-semibold text-secondary-black">
            Statement of Consent
          </h2>
          <p className="text-base text-dark-gray">
            I, {formData.name}, hereby grant my consent for{" "}
            {formData.minor_name} to travel to {formData.destination} with{" "}
            {formData.adult_name}{" "}
            {formData.date_trip_end
              ? `from ${formatDate(formData.date_trip_start)} to ${formatDate(
                  formData.date_trip_end
                )}`
              : `starting at ${formatDate(formData.date_trip_start)}`}
            . This consent is given freely without any coercion.
          </p>
        </section>
      </div>
      <div className="border-b-2 p-4" />

      <p className="mb-8 pt-4">
        If there is any changes you want to have made, please press "Back" and
        arrange the changes.
      </p>
      <div className="flex flex-center">
        <NavLink
          onClick={onClose}
          to={"/consent/4"}
          className="back-form-button flex flex-center bg-primary-blue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md continue"
        >
          Back
        </NavLink>

        <button
          onClick={onProceed}
          className="flex flex-center bg-primary-blue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md continue self-center"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default PreviewPopup;
