import React, { useState } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { ModalContext } from "../contexts";
import User from "../entities/User";
import { alertError, alertSuccess } from "../helpers/notifcation";

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 22222;
  background: rgba(0, 0, 0, 0.3);
`;
const ModalContainer = styled(motion.div)`
  width: 100%;
  max-width: 998px;
  height: 50%;
  min-height: 466px;
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
`;
const CloseButton = styled.svg`
  width: 13px;
  height: 13px;
  fill: #1f2229;
  position: absolute;
  right: 18px;
  top: 18px;
  cursor: pointer;
`;

const modalVariant = {
  initial: { opacity: 0 },
  isOpen: { opacity: 1 },
  exit: { opacity: 0 },
};
const containerVariant = {
  initial: { top: "-50%", transition: { type: "spring" } },
  isOpen: { top: "50%" },
  exit: { top: "-50%" },
};

function ContactForm() {
  const [formData, setFormData] = useState({});
  const { closeModal: handleClose, modalIsOpen: isOpen } =
    React.useContext(ModalContext);

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    alertSuccess("We will contact you!");
    await User.contact({
      email: formData.email,
      name: formData.full_name,
      phone: formData.phone,
      text: formData.message,
    });
    setFormData({});
    handleClose();
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <Overlay
          initial={"initial"}
          animate={"isOpen"}
          exit={"exit"}
          variants={modalVariant}
          onClick={handleClose}
        >
          <div className="close-button-hidden" onClick={handleClose}></div>
          <ModalContainer
            className={"modal-contact-form"}
            variants={containerVariant}
            onClick={stopPropagation}
          >
            <div className="bg-white top-[50%] translate-y-[-50%] rounded-[24px] max-w-[996px] absolute w-[100%] translate-x-[-50%] left-[50%] max-lg:bottom-0 max-lg:!rounded-b-none max-lg:!h-[100vh] max-lg:!overflow-auto modal">
              <div className="info">
                <h2 className="text-5xl font-semibold mb-4">Get in touch</h2>
                <img
                  src="/images/close-black.svg"
                  alt="close modal icon"
                  className="absolute right-[24px] top-[24px]"
                  onClick={handleClose}
                />
                <p className="mb-4 text-dark-gray">
                  Feel free to contact us with any questions or issues.
                </p>
                <div className="contact-info">
                  <p>You can also contact us directly:</p>
                  <div className="flex">
                    <i className="fas fa-envelope" />
                    <a href="mailto:info@travelconsent24.com">
                      info@travelconsent24.com
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="form-container"
                style={{ padding: "70px 40px 40px 0", borderRadius: 24 }}
              >
                <form onSubmit={handleFormSubmit}>
                  <div className="flex form-container-usual">
                    <div>
                      <div className="flex flex-col space-y-4">
                        <input
                          required
                          type="text"
                          name="full_name"
                          value={formData.full_name}
                          placeholder="Full name"
                          className="border-2 rounded-xl p-2"
                          onChange={handleInputChange}
                        />
                        <input
                          required
                          type="text"
                          name="email"
                          value={formData.email}
                          placeholder="Email address"
                          className="border-2 rounded-xl p-2"
                          onChange={handleInputChange}
                        />
                        <input
                          type="number"
                          name="phone"
                          value={formData.phone}
                          placeholder="Phone (optional)"
                          className="border-2 rounded-xl p-2"
                          onChange={handleInputChange}
                        />
                        <textarea
                          required
                          name="message"
                          value={formData.message}
                          placeholder="How can we help you?"
                          className="special_instructions border-2 rounded-xl p-2"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-center">
                    <button
                      type="submit"
                      className="flex flex-center bg-primary-blue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md continue"
                    >
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </ModalContainer>
        </Overlay>
      )}
    </AnimatePresence>
  );
}

export default ContactForm;
