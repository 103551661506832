import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { convertISOToDate } from "../helpers";
import User from "../entities/User";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Virtual } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

function BlogPage() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    User.destroy();
  }, []);

  useEffect(() => {
    User.getAllBlogs()
      .then((res) => setItems(res.data))
      .catch(() => {});
  }, []);

  return (
    <section>
      <div className="flex flex-col-mobile">
        <h1 className="font-semibold" style={{ flex: 1 }}>
          Blog highlights
        </h1>
        <div className="flex flex-center" style={{ flex: 1 }}></div>
      </div>
      <div className="py-12 bg-white blog-block">
        <div className="block-section-wrapper">
          <div className="blog-section">
            <Swiper
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                480: {
                  slidesPerView: 1.5,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2.5,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 3.5,
                  spaceBetween: 30,
                },
              }}
              modules={[Pagination, Navigation]}
              navigation={{ prevEl: ".arrow-left", nextEl: ".arrow-right" }}
            >
              {items.map((blog, index) => (
                <SwiperSlide
                  key={blog.id}
                  className="bg-primary-blue rounded-xl hover:bg-secondary-black"
                >
                  <div className="blog-item">
                    <div className="p-6 flex flex-col">
                      <p>{convertISOToDate(blog.createdAt)}</p>
                      <h3 className="text-xl font-semibold text-white mb-4">
                        {blog.title}
                      </h3>
                      <p className="text-base text-white mb-4">
                        {blog.description}
                      </p>
                      <NavLink
                        to={`/blog/${blog.id}`}
                        style={{ marginTop: "auto" }}
                      >
                        Read more{" "}
                        <img
                          src="/images/chevron-right.svg"
                          className="inline-block"
                        />
                      </NavLink>
                    </div>
                    <img
                      className="object-cover rounded-2xl p-2 w-[100%] max-h-[220px]"
                      src={blog.image}
                      alt={blog.id}
                      loading="lazy"
                    />
                  </div>
                </SwiperSlide>
              ))}
              <button className="arrow-left arrow">
                <img
                  src="/images/arrow-forward.svg"
                  alt="previous slide button"
                />
              </button>
              <button className="arrow-right arrow">
                <img src="/images/arrow-forward.svg" alt="next slide button" />
              </button>
            </Swiper>
          </div>
        </div>
      </div>
      <div className="recent-blogs-block">
        <h1 className="font-semibold mb-5">Recent posts</h1>
        {items.map((blog) => (
          <div
            className="bg-white shadow-md rounded-xl overflow-hidden mb-5 recent-blog-item flex-col-mobile"
            key={blog.id}
          >
            <img className="w-full" src={blog.image} alt={blog.id} />
            <div className="p-5 flex flex-col">
              <p className="text-sm text-gray-600">
                {convertISOToDate(blog.createdAt)}
              </p>
              <h2 className="text-xl font-semibold mt-2">{blog.title}</h2>
              <p className="text-gray-700 mt-3">{blog.description}</p>
              <NavLink
                to={`/blog/${blog.id}`}
                style={{ marginTop: "auto" }}
                className="text-primary-blue hover:text-blue-700 mt-4 inline-block"
              >
                Read more
              </NavLink>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default BlogPage;
