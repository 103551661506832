import React from 'react';
import ReactDOM from 'react-dom/client';
import 'tailwindcss/tailwind.css';
import './styles/index.css';
import './styles/checkout.css';
import './styles/form.css';
import './styles/about-us.css'
import './styles/modal.css'
import 'react-toastify/dist/ReactToastify.css';
import './styles/response.css'
import App from './App';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
        <App/>
    </BrowserRouter>
  </React.StrictMode>
);
