import { useRef, useState } from "react";

const VideoPlayer = ({ video }) => {
  const videoRef = useRef(null);
  const [isPaused, setIsPaused] = useState(true);

  const handlePlay = () => {
    setIsPaused(false);
    if (videoRef.current) {
      videoRef.current.controls = true;
    }
  };

  const handlePause = () => {
    setIsPaused(true);
    if (videoRef.current) {
      videoRef.current.controls = false;
    }
  };

  return (
    <div className="video-container">
      {isPaused && (
        <img
          src="/images/play_circle.svg"
          alt="play icon"
          className="overlay"
        />
      )}
      <video
        ref={videoRef}
        width="486"
        height="517"
        onPause={handlePause}
        onClick={handlePlay}
      >
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;
