import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import User from "../../entities/User";
import FileUpload from "../Custom/FileUpload";

function IdentityFormCustom() {
  const navigate = useNavigate();
  const [files, setFiles] = useState([{}]);

  const handleUploadFile = async (e) => {
    e.preventDefault();
    await User.saveDocument(files);
    navigate("/notarization/identity/complete?isOnlyNotarization");
  };

  const handleFileSet = (index) => (file) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles[index] = file;
      return newFiles;
    });
  };

  return (
    <form onSubmit={handleUploadFile} className="flex flex-center flex-col">
      <h3 className="font-semibold mb-6 text-4xl">Identity verification</h3>
      <p className="mb-8 w-[70%] text-center font-medium text-base text-dark-gray">
        In order to Notarize the Travel Consent we need to verify your identity.
        Upload a photo of your verification document. This can be your passport,
        ID card, driving license, or any other document that verifies your
        identity
      </p>
      <p className="mb-10 text-dark-gray">
        Only the person/persons granting travel consent need to verify their
        identity
      </p>
      <div
        className="mb-6 parent-container only-notarization"
        style={{ width: "100%" }}
      >
        {files.map((file, index) => (
          <div key={index} className="flex flex-col file-block-upload">
            <div
              className="count flex"
              style={{ marginRight: 40, marginBottom: 20 }}
            >
              <p
                className="font-semibold"
                style={{ color: "gray", fontSize: 18 }}
              >
                {index + 1}.
              </p>
              <p
                className="font-semibold"
                style={{ marginLeft: 30, fontSize: 18 }}
              >
                {index === 0 ? "First" : "Second"} parent/guardian*
              </p>
            </div>
            <div style={{ flexGrow: 1, marginLeft: 40 }}>
              <div className="flex flex-col space-y-4">
                <FileUpload set={handleFileSet(index)} />
              </div>
            </div>
            {files.length < 2 ? (
              <button
                type="button"
                className="text-primary-blue mt-4"
                onClick={() => setFiles([files[0], {}])}
              >
                + Add second parent/guardian
              </button>
            ) : (
              ""
            )}
            {files.length === 2 && index === 1 ? (
              <button
                type="button"
                className="text-red-500 mt-4"
                onClick={() => setFiles([files[0]])}
              >
                - Remove second parent/guardian
              </button>
            ) : (
              ""
            )}
          </div>
        ))}
      </div>
      <div className="flex flex-center">
        <NavLink
          onClick={() => localStorage.removeItem("isNotarized")}
          to={"/consent/finish?isCustomTravelConsent"}
          className="back-form-button flex flex-center bg-primary-blue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md continue"
        >
          Back
        </NavLink>
        <button
          onClick={() => localStorage.setItem("isNotarized", true)}
          type="submit"
          className="flex flex-center bg-primary-blue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md continue"
        >
          Continue
        </button>
      </div>
    </form>
  );
}

export default IdentityFormCustom;
