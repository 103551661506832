export function getStageClass(stage) {
  const path = window.location.pathname.split("/");
  if (Number(path[2]) === stage) return "active-stage";
  if (Number(path[2]) > stage) return "completed-stage";
  return "";
}

export function getCurrentStage() {
  const path = window.location.pathname.split("/");
  return Number(path[2]);
}

export function isFormPage() {
  const pathname = window.location.pathname;

  if (pathname.startsWith("/consent/1")) return false;

  return pathname.startsWith("/consent") || pathname.startsWith("/notarzation");
}

export function convertISOToDate(isoString) {
  // Create a new Date object from the ISO string
  const date = new Date(isoString);

  // Define options for date format: full month name and four digit year
  const options = { day: "2-digit", month: "long", year: "numeric" };

  // Convert date to specified format
  return date.toLocaleDateString("en-US", options);
}

export function isFullyFormPage() {
  const pathname = window.location.pathname;
  return (
    pathname.startsWith("/consent") ||
    pathname.startsWith("/notarization") ||
    pathname.startsWith("/checkout")
  );
}

export function isFinishConsent() {
  const path = window.location.pathname.split("/");
  return path[2] === "finish" || path[3] === "form";
}

export function isCustomTravelConsent() {
  const path = window.location.pathname.split("/");
  return path[3] === "form";
}

export function bytesToMB(val) {
  return (val / (1024 * 1024)).toFixed(1);
}

export function base64ToBlob(base64Image) {
  const splitImage = base64Image.split(",");
  const contentType = splitImage[0].split(":")[1].split(";")[0];
  const byteCharacters = atob(splitImage[1]);
  const arrayBuffer = new ArrayBuffer(byteCharacters.length);
  const uintArray = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteCharacters.length; i++)
    uintArray[i] = byteCharacters.charCodeAt(i);

  return new Blob([uintArray], { type: contentType });
}

export function isNotarizationChoosePage() {
  const pathname = window.location.pathname;
  return (
    pathname.startsWith("/notarization/choose") ||
    pathname.startsWith("/notarization/identity/complete") ||
    pathname.startsWith("/notarization/identity")
  );
}

export function isHomePage() {
  return window.location.pathname === "/";
}

export function parse(data) {
  try {
    return JSON.parse(data);
  } catch (e) {
    return null;
  }
}

export function stringify(data) {
  try {
    return JSON.stringify(data);
  } catch (e) {
    return null;
  }
}

export function isMobile() {
  return window.screen.width < 768;
}

export const stagesToScroll = {
  1: 0,
  2: 90,
  3: 310,
  4: 550,
  5: 610,
};
