import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import User from "../../entities/User";
import { ModalHowDigitalWorksContext } from "../../contexts";

function PaymentComplete() {
  const { openModalSignature } = React.useContext(ModalHowDigitalWorksContext);

  useEffect(() => {
    User.confirmPayment();
  }, []);

  return (
    <section className="">
      <div className>
        <div className="flex flex-col items-center mb-6">
          <img
            src="/icons/icon15.svg"
            className="mb-3 inline-block fas"
            alt="payment icon"
          />
          <h2 className="text-32 text-secondary-gray font-bold mb-2">
            Payment successful.
          </h2>
          <p
            className="text-32 text-secondary-black font-bold text-center-mobile"
            style={{ marginBottom: 18 }}
          >
            Would you like to sign the document digitally?
          </p>
          <p
            className="text-base text-dark-gray mb-6"
            style={{ marginBottom: 18, maxWidth: 800, textAlign: "center" }}
          >
            You can either sign the document digitally, or skip this process for
            now then put a physical signature by your hand once the document is
            printed out.
          </p>
        </div>
      </div>
      <div className="flex flex-center flex-col-mobile">
        <NavLink
          to={"/notarization/signature/complete"}
          style={{ width: 194 }}
          className="back-form-button flex flex-center bg-primary-blue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md continue"
        >
          Sign manually later
        </NavLink>
        <NavLink
          to={"/notarization/signature/digitally"}
          style={{ width: 194 }}
          className="flex flex-center bg-primary-blue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md continue"
        >
          Sign digital now
        </NavLink>
      </div>
      <div className="flex flex-center" style={{ marginTop: 30 }}>
        {" "}
        <span
          onClick={openModalSignature}
          className="text-primary-blue font-medium"
          style={{ cursor: "pointer" }}
        >
          How does this work?
        </span>{" "}
      </div>
    </section>
  );
}

export default PaymentComplete;
