import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import ChooseForm from "./ChooseForm";
import IdentityForm from "./IdentityForm";
import IdentityComplete from "./IdentityComplete";
import User from "../../entities/User";
import PaymentComplete from "./PaymentComplete";
import { isNotarizationChoosePage } from "../../helpers";
import SignedComplete from "./SignedComplete";
import SignedDigitally from "./SignedDigitally";
import IdentityFormCustom from "./IdentityFormCustom";

function NotarizationForm() {
  useLocation();

  return (
    <section className="form-section">
      <div className="w-full max-w-3xl mx-auto bg-white p-8 my-10 form-title" />
      <div className={`rounded-xl shadow-md form `}>
        <Routes>
          <Route path="/choose" element={<ChooseForm />} />
          <Route path="/identity" element={<IdentityForm />} />
          <Route path="/identity/custom" element={<IdentityFormCustom />} />
          <Route path="/identity/complete" element={<IdentityComplete />} />
          <Route path="/payment/complete" element={<PaymentComplete />} />
          <Route path="/signature/complete" element={<SignedComplete />} />
          <Route path="/signature/digitally" element={<SignedDigitally />} />
        </Routes>
      </div>

      <div className="w-full bg-white p-4 form-bottom-section">
        {isNotarizationChoosePage() && (
          <div className="flex justify-between items-center max-w-3xl mx-auto">
            <div className="flex items-center">
              <img
                src="/images/user.svg"
                className="mr-2 inline-block fas w-[32px]"
                alt="user icon"
              />
              Notarization by demand
            </div>
            <div className="flex items-center">
              <img
                src="/images/users.svg"
                className="mr-2 inline-block fas w-[32px]"
                alt="users icon"
              />
              100% user satisfaction
            </div>
            <div className="flex items-center">
              <img
                src="/images/lock.svg"
                className="mr-2 inline-block fas w-[32px]"
                alt="lock icon"
              />
              Fast & Secure
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default NotarizationForm;
