import { base64ToBlob, parse, stringify } from "../helpers";
import fetch from "../helpers/fetch";
import { v4 as uuidv4 } from "uuid";

class User {
  constructor() {
    if (!localStorage.getItem("uuidv4"))
      localStorage.setItem("uuidv4", uuidv4());
    this._uuid = localStorage.getItem("uuidv4");
    this._email = localStorage.getItem("email_user");
    this._formData = parse(localStorage.getItem("formData"));
  }

  destroy() {
    this._formData = null;
    this._email = null;
    this._travelConsentFile = null;
    this._uuid = null;
    localStorage.clear();
  }

  get uuid() {
    if (!this._uuid) {
      localStorage.setItem("uuidv4", uuidv4());
      this._uuid = localStorage.getItem("uuidv4");
      return this._uuid;
    } else {
      return this._uuid;
    }
  }

  set email(value) {
    this._email = value;
    localStorage.setItem("email_user", value);
  }

  set travelConsentFile(file) {
    this._travelConsentFile = file;
  }

  set formData(value) {
    this._formData = value;
    localStorage.setItem("formData", stringify(value));
  }

  get formData() {
    return this._formData;
  }

  get email() {
    return this._email;
  }

  async save() {
    const data = this._formData;
    data[0].email = this._email;
    try {
      await fetch.post("/consent/form/complete", data, {
        headers: {
          "process-id": this.uuid,
        },
      });
    } catch (error) {}
  }

  async confirmPayment() {
    const queryParams = new URLSearchParams(location.search);
    const paymentID = queryParams.get("session_id");
    const formData = this.formData;
    if (!paymentID) return;
    await fetch.post(
      "/notarization/confirm/payment",
      { paymentID, formData },
      {
        headers: {
          "process-id": this.uuid,
        },
      }
    );
  }

  async uploadTravelConsent(document, email) {
    const formData = new FormData();
    formData.append("file", this._travelConsentFile);
    formData.append("email", this._email);
    try {
      await fetch.post("/notarization/consent/document", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "process-id": this.uuid,
        },
      });
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }

  async generateTravelConsent() {
    await fetch.post(
      "/consent/form/generate",
      {},
      {
        headers: {
          "process-id": this.uuid,
        },
      }
    );
  }

  async getInformation(id) {
    return fetch.get("/information/" + id);
  }

  async getAllInformation() {
    return fetch.get("/information/");
  }

  async saveDocument(documents) {
    if (!documents.length) return;

    const formData = new FormData();
    documents
      .filter((document) => !!document)
      .map((document) => formData.append("files", document));
    formData.append("email", this._email);

    try {
      const res = await fetch.post("/notarization/document", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "process-id": this.uuid,
        },
      });
      return res;
    } catch (error) {
      return { error: "File should be less than 10MB" };
    }
  }

  async contact(body) {
    await fetch.post("/contact", body, {
      headers: {
        "process-id": this.uuid,
      },
    });
    this.destroy();
  }

  async signedManually() {
    await fetch.patch(
      "/notarization/signature/manually",
      { email: this._email },
      {
        headers: {
          "process-id": this.uuid,
        },
      }
    );
  }

  async signedDigitally(signatures) {
    const formData = new FormData();
    signatures.map((signature) => {
      formData.append("files", base64ToBlob(signature), "signature.png");
    });
    formData.append("email", this._email);

    try {
      await fetch.post("/notarization/signature/digitally", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "process-id": this.uuid,
        },
      });
      // this.destroy()
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }

  async getAllBlogs() {
    return fetch.get("/blog");
  }

  async getOneBlog(id) {
    return fetch.get("/blog/" + id);
  }
}

export default new User();
