import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import User from "../../entities/User";
import { ModalContext } from "../../contexts";

function SignedComplete() {
  const { openModal } = React.useContext(ModalContext);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const isSigned = queryParams.get("isSigned") === "";
    if (!isSigned && User.formData) User.signedManually();
    User.confirmPayment();
  }, []);

  return (
    <section className="">
      <div className>
        <div className="flex flex-col items-center mb-6">
          <img
            src="/icons/icon14.svg"
            className="mb-3 inline-block fas"
            alt="1 icon"
          />
          <p
            className="text-3xl text-secondary-black font-bold"
            style={{ marginBottom: 18, textAlign: "center", width: "60%" }}
          >
            Document will be sent to your email{" "}
            {localStorage.getItem("isNotarized") === "true"
              ? "as soon as we notarize them"
              : ""}
          </p>
          <p className="text-base text-dark-gray mb-6">
            Usually this process takes 0-2 hours. Contact
            info@travelconsent24.com for any questions.
          </p>
        </div>
      </div>
      <div
        className="flex flex-center"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <p
          className="text-base text-dark-gray mb-6"
          style={{ marginBottom: 30 }}
        >
          Thank you!
        </p>
        <NavLink
          to={"#"}
          style={{ width: 194 }}
          className="flex flex-center bg-primary-blue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md continue"
          onClick={openModal}
        >
          Get in touch
        </NavLink>
      </div>
    </section>
  );
}

export default SignedComplete;
