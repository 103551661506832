import React, { useEffect, useState } from "react";
import { ModalContext } from "../contexts";
import User from "../entities/User";

function TermsAndConditions() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isAbsolute, setIsAbsolute] = useState(null);
  const { openModal } = React.useContext(ModalContext);
  const hash = window.location.hash;

  const scrollListener = () => {
    window.scrollY > document.getElementById("left-side").offsetTop - 20
      ? setIsScrolled(true)
      : setIsScrolled(false);

    window.scrollY > document.getElementById("section6").offsetTop + 120
      ? setIsAbsolute(true)
      : setIsAbsolute(null);
  };
  window.addEventListener("scroll", scrollListener);

  useEffect(() => {
    User.destroy();
    return () => {
      window.removeEventListener("scroll", scrollListener);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <section className="faq-page terms">
      <div className="title-faq">
        <p className="updated-date">Last updated: February 12, 2024</p>
        <h1 className="font-semibold" style={{ paddingTop: 10 }}>
          Terms and Conditions
        </h1>
        <p>
          By using Travel Consent services you automatically agree to our Terms
          & Conditions
        </p>
      </div>
      <div className="info-page">
        <div className="left-side" id="left-side">
          <div
            className={`anchor-block ${isScrolled ? "fixed" : ""} ${
              isAbsolute ? "absolute-123" : ""
            }`}
          >
            <h3 className="font-semibold">ON THIS PAGE</h3>
            <a
              href="#section1"
              className={hash === "#section1" ? "active" : ""}
            >
              Acceptance of Terms
            </a>
            <a
              href="#section2"
              className={hash === "#section2" ? "active" : ""}
            >
              Descriptions of Service
            </a>
            <a
              href="#section3"
              className={hash === "#section3" ? "active" : ""}
            >
              Limitation of Liability and Disclaimer of Warranties
            </a>
            <a
              href="#section4"
              className={hash === "#section4" ? "active" : ""}
            >
              Privacy Policy
            </a>
            <a
              href="#section5"
              className={hash === "#section5" ? "active" : ""}
            >
              User conduct
            </a>
            <a
              href="#section6"
              className={hash === "#section6" ? "active" : ""}
            >
              Termination of Service
            </a>
            <a
              href="#section7"
              className={hash === "#section7" ? "active" : ""}
            >
              Changes to Terms
            </a>
            <a
              href="#section8"
              className={hash === "#section8" ? "active" : ""}
            >
              Contact information
            </a>
          </div>
          <a className="scroll-to-top-button" onClick={scrollToTop}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_1564_2436"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_1564_2436)">
                <path
                  d="M11 11.8V15C11 15.2833 11.0958 15.5208 11.2875 15.7125C11.4792 15.9042 11.7167 16 12 16C12.2833 16 12.5208 15.9042 12.7125 15.7125C12.9042 15.5208 13 15.2833 13 15V11.8L13.9 12.7C14.0833 12.8833 14.3167 12.975 14.6 12.975C14.8833 12.975 15.1167 12.8833 15.3 12.7C15.4833 12.5167 15.575 12.2833 15.575 12C15.575 11.7167 15.4833 11.4833 15.3 11.3L12.7 8.7C12.5 8.5 12.2667 8.4 12 8.4C11.7333 8.4 11.5 8.5 11.3 8.7L8.7 11.3C8.51667 11.4833 8.425 11.7167 8.425 12C8.425 12.2833 8.51667 12.5167 8.7 12.7C8.88333 12.8833 9.11667 12.975 9.4 12.975C9.68333 12.975 9.91667 12.8833 10.1 12.7L11 11.8ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                  fill="#9C9C9C"
                />
              </g>
            </svg>
            Back to top
          </a>{" "}
        </div>
        <div className="right-side terms-page">
          <h2 className="font-semibold" id="section1">
            Acceptance of Terms
          </h2>
          <p>
            By accessing or using the Travelconsent24.com website (“Website”)
            and services (“Services”), you agree to comply with and be bound by
            these Terms and Conditions (“Terms”). If you do not agree to these
            Terms, please do not use this Website.
          </p>
          <h2 className="font-semibold" id="section2">
            Descriptions of Service
          </h2>
          <p>
            Travelconsent24.com is a provider of online Notary Services and
            creation of Travel Consent Forms.
          </p>
          <h2 className="font-semibold" id="section3">
            Limitation of Liability and Disclaimer of Warranties
          </h2>
          <p>
            We cannot guarantee that a third party reviewing documents notarized
            by our Services will accept them. We take no responsibility for the
            refusal of any third party to accept a document notarized through
            our Services. However we will issue a refund if a country that is a
            part of the Apostille-convention for some reason refuses to accept
            the Apostilled document. Further, Travelconsent24.com does not
            verify the content of the documents you wish to notarize. The job of
            a Notary is to witness the signing of documents and verify the
            identity of the signers, not to ensure the accuracy or legality of
            the documents themselves other than acknowledging them.
          </p>
          <h2 className="font-semibold" id="section4">
            Privacy Policy
          </h2>
          <p>
            Your privacy is important to us. Our Privacy Policy is incorporated
            by reference into these Terms and provides further information
            regarding the collection and use of your data.
          </p>
          <h2 className="font-semibold" id="section5">
            User conduct
          </h2>
          <p>
            By using this Website, you agree not to forge documents, mislead, or
            deceive Travelconsent24.com or the Notaries working through this
            Website. Any such activities will result in termination of Services
            and could lead to legal action.
          </p>
          <h2 className="font-semibold" id="section6">
            Termination of Service
          </h2>
          <p>
            We reserve the right to terminate or suspend access to our Services,
            without notice, for conduct that we believe violates these Terms or
            is harmful to us, our users, or third parties, or for any other
            reason.
          </p>
          <h2 className="font-semibold" id="section7">
            Changes to Terms
          </h2>
          <p>
            Travelconsent24.com may change these Terms from time to time. When
            we do, we will update the “Last Updated” date. Your continued use of
            this Website following the posting of any changes constitutes
            acceptance of those changes.
          </p>
          <h2 className="font-semibold" id="section8">
            Contact information
          </h2>
          <p>
            For any questions about these Terms, please contact us at{" "}
            <span>info@travelconsent24.com.</span>
          </p>
        </div>
      </div>
    </section>
  );
}

export default TermsAndConditions;
